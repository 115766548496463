import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';
import { Link } from 'react-router-dom';


import './_link.scss';

const LinkComp = ({
    children = '',
    className = '',
    href = '',
    target = '_self',
    arrow = false,
}) => {
    return (
        <Link
            className={ConcatClasses([
                arrow ? 'link--border' : '',
                'link',
                className,
            ])}
            to={href}
            target={target}
        >
            {children}
            {arrow ? (
                <i className="arrow" />
            ) : ''}
        </Link>
    );
}

export default LinkComp;