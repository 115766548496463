import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

const Form = ({
    className = '',
    children = "", 
    onSubmit = () => {},
}) => {

  return (
    <form
      className={ConcatClasses([
        className,
      ])}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
}

export default Form;
