import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-modern-calendar-datepicker';
import axios from 'axios';

import ConcatClasses from '../../utils/ConcatClasses';
import CalAgeClasses from '../../utils/CalAgeClasses';

// import Calendar from '../smart/Calendar';
import Checkbox from '../simple/Checkbox';
import Form from '../smart/Form';
import Footer from '../simple/Footer';
import Icon from '../simple/Icon';
import Input from '../simple/Input';
import Image from '../simple/Image';
import Months from '../simple/Months';
import States from '../simple/States';

import FormNav from '../simple/FormNav';
import Terms from '../simple/Terms';
import Text from '../simple/Text';
import TextArea from '../simple/TextArea';

// import './apply.scss';
const birthdayIndex = 4;

const Apply = ({
  content = {},
}) => {
  // page content
  const {
    pageHeading,
    hero,
    success,
    error,
    questions,
  } = content;
  // hooks
  const [curSection, setSection] = useState(1);
  const [formOne, setFormOne] = useState([null, null, null, null, null]);
  const [nextButton, setNextButton] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [textArea, setTextArea] = useState({});
  const [inputData, setInputData] = useState({});
  const [birthDate, setBirthDate] = useState({dob: undefined});
  const [selectedDay, setSelectedDay] = useState({
    month: null,
    day: '0',
    year: '0',
  });
  const [unavailableDaysArray, setUnavailable] = useState([]);
  // use effects
  useEffect(() => {
    handleAgeCheck(selectedDay)
  }, [selectedDay]);

  // defaults
  const inputFields = [
    {
      required: true,
      placeHolder: 'Email',
      pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
      type: 'email',
      name: 'email',
    },
    {
      required: true,
      placeHolder: 'First Name',
      pattern: '[A-Za-z0-9]+',
      type: 'text',
      name: 'first-name',
    },
    {
      required: true,
      placeHolder: 'Last Name',
      pattern: '[A-Za-z0-9]+',
      type: 'text',
      name: 'last-name',
    },
    {
      required: true,
      placeHolder: 'City',
      pattern: '[^0-9]*',
      type: 'text',
      name: 'city',
    },
    {
      required: true,
      placeHolder: 'State',
      pattern: '[^0-9]*',
      type: 'text',
      name: 'state',
    },
  ];

  const prodTypes = [
    {
      id: 'travel',
      info: 'We will fly you from your town/city to a destination in the US, to be in person at the shoot.',
      name: 'travel',
      formName: 'apply',
      text: 'On Site with Air Travel',
      value: 'travel',
    },
    {
      id: 'onsite',
      info: 'We will pair you with shoots happening in your town/city, if available, and you’ll travel by car to the shoot.',
      name: 'onsite',
      formName: 'apply',
      text: 'On Site Local',
      value: 'onsite',
      
    },
    {
      id: 'online',
      info: 'We will pair you with shoots that allow remote viewing, so you can attend the shoot from your own home.”',
      name: 'online',
      formName: 'apply',
      text: 'Online or Zoom',
      value: 'online',
    },
    {
      id: 'any',
      info: 'Just like it sounds!',
      name: 'production-any',
      formName: 'apply',
      text: 'Open to Any',
      value: 'productionAny',
    }
  ];
  const roleTypes = [
    {
      id: 'art-director',
      name: 'art-director',
      formName: 'apply',
      text: 'Art Director',
      value: 'artDirector',
    },
    {
      id: 'copywriter',
      name: 'copywriter',
      formName: 'apply',
      text: 'Copywriter',
      value: 'copywriter',
    },
    {
      id: 'producer',
      name: 'producer',
      formName: 'apply',
      text: 'Producer',
      value: 'producer',
    },
    {
      id: 'account-manager',
      name: 'account-manager',
      formName: 'apply',
      text: 'Account Manager',
      value: 'accountManager',
    },
    {
      id: 'role-open-to-any',
      name: 'role-open-to-any',
      formName: 'apply',
      text: 'Open to Any',
      value: 'rolesAny',
    }
  ]
  const formPages = [
    {
      section: "one"
    },
    {
      section: "two"
    },
    {
      section: "three"
    }
  ]
  const handleValidation = (e, index) => {
    const valid = e.target.checkValidity();
    const tempArray = formOne;
    tempArray[index] = valid;
    setFormOne(tempArray);
    validateAllInput(formOne);
  }

  // validate every input per section
  const validateAllInput = () => {
    const every = formOne.every((item) => item === true);
    if(every) {
      setNextButton(true);
    } else {
      setNextButton(false);
    }
  }

  const returnCheckbox = (type, border) => {
    const {
      id,
      info,
      name,
      formName,
      text,
    } = type;
  
    return (
      <Checkbox 
        className={
          ConcatClasses([
            'dis--block',
            border ? 'bor--grey-b-1' : '',
            'float--right',
            'pad--b-10',
            'pad--t-10',
            'text--left',
            'width--100-p',
          ])
        }
        key={id}
        id = {id}
        info = {info}
        name = {name}
        onChange = {(e) => handleCheckboxChanges(e)}
        formName = {formName}
        text = {text}
        checked={checkedItems[name]}
      />
    )
  }

  const renderInputFields = inputFields.map((input, index) => {
    const {
      required,
      placeHolder,
      pattern,
      type,
      name,
    } = input;

    if (name === 'state') {
      return (
        <States
          className={ConcatClasses([
            'dis--block',
            'input--white',
            'mar--b-40',
            'width--100-p',
          ])}
          key={placeHolder}
          onBlur={(e) => handleValidation(e, index)}
          onChange={(e) => handleContactInformation(e)}
          placeHolder={placeHolder}
          required={required}
        />
      )
    }
  
    return (
      <Input
        className={ConcatClasses([
          'dis--block',
          'input--white',
          'mar--b-40',
          'width--100-p',
          `valid--${formOne[index]}`
        ])}
        key={placeHolder}
        onBlur={(e) => handleValidation(e, index)}
        required={required}
        onChange={(e) => handleContactInformation(e)}
        placeHolder={placeHolder}
        pattern={pattern}
        type={type}
        name={name}
      />
    );
  })
  const renderProdTypes = prodTypes.map((type, index) => {
      const border = prodTypes.length !== index + 1;
      return (returnCheckbox(type, border))
  })
  const renderRoleTypes = roleTypes.map((type, index) => {
    const border = roleTypes.length !== index + 1;
    return (returnCheckbox(type, border))
  })
  const handleAgeCheck = (date) => {
    let index = birthdayIndex;

    console.log('AGE CHECK  ', !date.month, date.day.length <= 0, date.year.length  <= 2)

    if(!date.month || date.day.length <= 0 || date.year.length  <= 2) {
      return null;
    }

    let overEighteen = CalAgeClasses((`${date.month}  ${date.day} ${date.year}`))
    const tempArray = formOne;

    if (overEighteen >= 18 && overEighteen <= 200) {
      tempArray[index] = true;
      const new_date = `${date.month} ${date.day} ${date.year}`

      setBirthDate({dob: new_date});

    } else if (overEighteen <= 17) {
      tempArray[index] = false;
      setBirthDate({dob: null});
    }

    setFormOne(tempArray);
    validateAllInput(formOne);
  }
  const handleContactInformation = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setInputData({...inputData, [name]: value})
  }
  const handleUnavailable = (value) => {
    if (!value.from || !value.to) {
    } else {
      setInputData({...inputData, unavailableDays :
        [...unavailableDaysArray, value],
      })
      setUnavailable([...unavailableDaysArray, value]);
    }
  }

  const handleCheckboxChanges = (e) => {
    const target = e.target;
    setInputData({...inputData, [e.target.name] : e.target.checked})
  }

  const handleTextAreaChanges = (e) => {
    const target = e.target;
    setInputData({...inputData, [e.target.name] : e.target.value})
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const url = 'https://openingshot.org/user-submission/';
    const combinedData = {
      ...inputData,
      ...birthDate,
    }

  
    // Need to send as form data
    const formData = new FormData();
    formData.set('data', JSON.stringify(combinedData));

    console.log('SUBMIT INPUT DATA----------------', combinedData, formData)

    axios.post(url, formData)
    .then(function (response) {
      setSection(200);
      console.log('HANDLE SUCESS', response);

    })
    .catch(function (error) {
      setSection(200);
      console.log('HANDLE ERROR ', error);
    });
  }

  return (
    <div
      className={ConcatClasses([
        'bg--black',
        'xx--t-r',
      ])}
    >
      <section
        className={ConcatClasses([
          'bg--lrg-black',
          'mar--0-auto',
          'pad--b-100',
          'pad--t-100',
          'text--center',
          'width--90-p',
        ])}
      >
        {curSection === 200 ? (
          <div>
            <Text
              className={ConcatClasses([
                'heading--1',
                'mar--b-30',
                'mar--lrg-b-80',
                'text--white',
              ])}
            >
              {pageHeading}
            </Text>
          </div>
        ) : ''}
        <Image
            alt={hero.alt}
            className={ConcatClasses([
              'dis--inline-block',
              'vis--lrg-hidden',
              'mh--185',
            ])}
            src={hero.headingImage}
        />
        <Form
          className={ConcatClasses([
            'dis--lrg-inline-block',
            'pad--lrg-r-50',
            'mw--lrg-370-p',
            'width--lrg-50-p',
          ])}
          onSubmit={(e) => handleFormSubmit(e)}
        >
          {/* SLIDE ONE */}
          <div
            className={ConcatClasses([
              'mar--0-auto',
              'transition--fade',
              curSection === 1 ? 'transition--in' : 'transition--out',
            ])}
          >
            <div
              className={ConcatClasses([
                  'text--left',
                ])}
              >
                <Text
                  className={ConcatClasses([
                    'text--black',
                    'text--left',
                    'text--white',
                  ])}
                >
                  Birthday<br /><span className='text--small'>You must 18+ yrs</span>
                </Text>
                <div
                  className={ConcatClasses([
                    '',
                  ])}
                >
                  <div
                    className={ConcatClasses([
                      'bg--black',
                    ])}
                  > 
                    <Months
                      className={ConcatClasses([
                        'input--white',
                        `valid--${birthDate.dob === null ? 'false': 'true'}`,
                      ])}
                      onChange={(e) => {
                        setSelectedDay({...selectedDay, month:  e.target.value});
                      }}
                    />
                    <Input
                      className={ConcatClasses([
                        'input--white',
                        'mar--b-40',
                        'mar--l-10',
                        `valid--${birthDate.dob === null ? 'false': 'true'}`,
                        'width--30-p',
                      ])}
                      key='Day'
                      min='1'
                      max='2'
                      maxLength="2"
                      required={true}
                      onChange={(e) => {
                        setSelectedDay({...selectedDay, day:  e.target.value});
                      }}
                      placeHolder='DD'
                      type='text'
                      name='day'
                    />
                    <Input
                      className={ConcatClasses([
                        'input--white',
                        'mar--b-40',
                        'mar--l-10',
                        `valid--${birthDate.dob === null ? 'false': 'true'}`,
                        'width--30-p',
                      ])}
                      key='Year'
                      maxLength='4'
                      required={true}
                      onChange={(e) => {
                        setSelectedDay({...selectedDay, year:  e.target.value});
                      }}
                      placeHolder='YYYY'
                      type='text'
                      name='year'
                    />
                  </div>
                </div>
            </div>
            {renderInputFields}
          </div>
          {/* SLIDE TWO */}
          <div
            className={ConcatClasses([
              'mar--0-auto',
              'text--center',
              'ovf--lrg-visible',
              'transition--fade',
              curSection === 2 ? 'transition--in' : 'transition--out',
              'width--lrg-100-p',
            ])}
          >
            <Text
              className={
                ConcatClasses([
                  'heading--1',
                  'mar--b-10',
                  'text--left',
                  'text--white',
                ])
              }
            >
              TYPES OF PRODUCTION
            </Text>
            <div
              className={
                ConcatClasses([
                  'bg--white',
                  'pad--10'
                ])
              }
            >
              {renderProdTypes}
            </div>
            <Text
              className={
                ConcatClasses([
                  'heading--1',
                  'mar--b-10',
                  'mar--t-40',
                  'text--left',
                  'text--white',
                ])
              }
            >
              ROLES INTERESTED IN
            </Text>
            <div
              className={
                ConcatClasses([
                  'bg--white',
                  'mar--b-40',
                  'pad--10',
                ])
              }
            >
              {renderRoleTypes}
            </div>
          </div>
              
          {/* SLIDE THREE */}
          <div
            className={
              ConcatClasses([
                'mar--0-auto',
                'mar--b-40',
                'text--center',
                'transition--fade',
                'ovf--lrg-visible',
                curSection === 3 ? 'transition--in' : 'transition--out',
              ])
            }
          >
            <Text
              className={
                ConcatClasses([
                  'heading--1',
                  'mar--b-10',
                  'mar--t-40',
                  'text--left',
                  'text--white',
                ])
              }
            >
              {questions[0].question}
              {questions[0].details ? (
                <Fragment>
                  <input 
                    type="checkbox"
                    id="info"
                    className={ConcatClasses([
                        'vis--hidden',
                    ])}
                  />
                  <label 
                    htmlFor="info"
                    className={ConcatClasses([
                        'info--label',
                        'pointer--cursor',
                        'mar--0-auto',
                    ])}
                  >
                    <Icon
                      className={ConcatClasses([
                        'icon--info',
                        'pointer--cursor',
                      ])}
                    />
                  </label>
                  <span
                    className="prompt--wrapper"
                  >
                    {questions[0].details}
                  </span>
                </Fragment>
              ) : ''}
            </Text>
            <TextArea 
              className={ConcatClasses([
                'mar--b-40',
                'mar--r-20',
                'mh--300',
                'width--90-p',
                'pad--20',
              ])}
              id='id'
              name='advertising'
              rows='4'
              cols='50'
              onChange={(e) => handleTextAreaChanges(e)}
            />

            <Text
              className={
                ConcatClasses([
                  'heading--1',
                  'mar--b-10',
                  'mar--t-40',
                  'text--left',
                  'text--white',
                ])
              }
            >
              {questions[1].question}
            </Text>
            <TextArea 
              className={ConcatClasses([
                'mar--r-20',
                'mh--300',
                'width--90-p',
                'pad--20',
              ])}
              id='id'
              name='advertising-interests'
              rows='4'
              cols='50'
              onChange={(e) => handleTextAreaChanges(e)}
            />

            <Checkbox 
              className={
                ConcatClasses([
                  'dis--block',
                  'float--right',
                  'pad--b-10',
                  'pad--t-10',
                  'text--left',
                  'text--white',
                  'width--100-p',
                ])
              }
              checked={false}
              color="white"
              key='agree'
              id='agree'
              terms={true}
              name='agree'
              onChange={(e) => {}}
              formName='pledge'
              text="I agree to the "
              required
            />
          </div>

          <div
            className={
              ConcatClasses([
                curSection === 200 || curSection === 500 ? 'vis--hidden' : '',
                'mar--0-auto',
                'width--lrg-100-p',
              ])
            }
          >

          <FormNav
              color='white'
              curSection={curSection}
              nextButton={nextButton}
              paginationArray={formPages}
              setSection={setSection}
            />
          </div>
          {/* SUCCESS || ERROR */}
          <div
            className={ConcatClasses([
              'bor--white-r-1-lrg',
              'mw--lrg-500-p',
              'pad--lrg-r-50',
              curSection === 200 || curSection === 500 ? 'transition--in mh--lrg-330 vertical-align--center' : 'transition--out',
            ])}
          >
            <div
                className={ConcatClasses([
                  'text--left',
                  'text--white',
                ])}
              >
              
              {curSection === 200 ? (
                <div dangerouslySetInnerHTML={{__html: success.copy}}  />
              ) : ''}
              {curSection === 500 ? (
                <div dangerouslySetInnerHTML={{__html: error.copy}}  />
              ) : ''}
            </div>
          </div>
        </Form>
        <div
          className={ConcatClasses([
            'dis--lrg-inline-block',
            'text--left',
            'trans--l-30p-t-18p',
            'ver-align--t',
          ])}
        >
          <Image
              alt={hero.alt}
              className={ConcatClasses([
                'dis--inline-block',
                'mw--340-p',
                'vis--hidden',
                'vis--lrg-visible',
                'width--100-p',
              ])}
              src={hero.headingImage}
          />
        </div>
      </section>
      <div
          className={
              ConcatClasses([
                  'width--100-p',
              ])
          }
      >
          <Terms 
              className={
                  ConcatClasses([
                      'bg--yellow',
                      'bg--lrg-black',
                      'text--center',
                      'pad--b-10',
                      'pad--t-10',
                  ])
              }
          />
          <Footer
              className={
                  ConcatClasses([
                      'bg--white',
                      'bg--lrg-black',
                      'pad--b-20',
                      'pad--t-20',
                      'text--center',
                      'text--lrg-left',
                  ])
              }
          />
      </div>
    </div>
  );
}

export default Apply;
