import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import Footer from '../simple/Footer';
import Text from '../simple/Text';
import TermsComp from '../simple/Terms';

import './_terms.scss';

const Terms = ({
  content = {},
}) => {
  const {
    terms,
  } = content;

  return (
    <div
        className={ConcatClasses([
            'bg--white',
            'pad--t-50',
            'width-100-p',
            'terms--page',
        ])}
    >
        <section
            className={ConcatClasses([
                'text--center',
                'mh--lrg-700',
                'mar--lrg-l-105',
            ])}
        >
            <Text
                className={ConcatClasses([
                    'heading--1',
                    'heading--lrg-0',
                    'mar--b-50',
                    'text--center',
                    'width--50-p',
                ])}
            >
                OPENING SHOT TERMS OF USE
            </Text>
            <div
                className={ConcatClasses([
                    'bg--black',
                    'dis--inline-block',
                    'mar--0-auto',
                    'mar--b-20',
                    'mar--lrg-b-20p',
                    'mar--lrg-r-20',
                    'pad--40',
                    'width--60-p',
                ])}
            >
                <div
                    className={ConcatClasses([
                        'text--white',
                    ])}
                    dangerouslySetInnerHTML={{__html: terms.copyOne}}
                />
            </div>
        </section>
        <div
            className={
                ConcatClasses([
                    'btm--lr',
                    'pos--lr-abs',
                    'width--100-p',
                ])
            }
        >
            <TermsComp 
                className={
                    ConcatClasses([
                        'bg--yellow',
                        'bg--lrg-black',
                        'text--center',
                        'pad--b-10',
                        'pad--t-10',
                        'vis--hidden',
                        'vis--lrg-visible',
                    ])
                }
            />
            <Footer
                className={
                    ConcatClasses([
                        'bg--white',
                        'bg--lrg-black',
                        'pad--b-20',
                        'pad--t-20',
                        'text--center',
                        'text--lrg-left',
                    ])
                }
            />
        </div>
    </div>
  );
}

export default Terms;
