import React, { Fragment } from 'react';

import ConcatClasses from '../../utils/ConcatClasses';

import LinkComp from '../simple/Link';

import Icon from '../simple/Icon';

import './_checkbox.scss';

const Checkbox = ({
    className = '',
    color = 'black',
    id = '',
    info = null,
    terms = false,
    name = '',
    onChange = () => {},
    formName = '',
    text = '',
    required = false,
    value = undefined, 
}) => {

  return (
      <div
        className={ConcatClasses([
            className,
        ])}
      >
        <label 
            className={ConcatClasses([
                'checkbox-label',
                `checkbox--${color}`,
            ])}
            htmlFor={`${formName}-${id}`}
        >
            {text}
        </label>
        {info ? (
          <div
            className={ConcatClasses([
                'dis--inline-block',
            ])}
          >
            <input 
              type="checkbox"
              id={`info-${name}`}
              className={ConcatClasses([
                  'vis--hidden',
              ])}
              name={`info-${name}`}
            />
            <label 
              htmlFor={`info-${name}`}
              className={ConcatClasses([
                  'info--label',
                  'pointer--cursor',
                  'mar--0-auto',
                  'mar--l-10',
              ])}
            >
              <Icon
                className={ConcatClasses([
                  'icon--info-grey',
                  'pointer--cursor',
                ])}
              />
            </label>
            <span
              className="prompt--wrapper"
            >
              {info}
            </span>
          </div>
        ) : ''}
        {terms ? (
            <LinkComp
                className={ConcatClasses([
                    `link--${color}`,
                    'text--underline',
                ])}
                href='/terms'
                target='_blank'
            >
                terms and conditions*
            </LinkComp>
        ) : ''}
        <input 
            className={ConcatClasses([
                'checkbox',
            ])}
            type="checkbox"
            id={id}
            name={name}
            onChange={onChange}
            required={required}
            value={value} 
        />
      </div>

  );
}

export default Checkbox;
