import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import Image from '../simple/Image';

import './_hero.scss';

const Hero = ({
  className = '',
  children = '',
  heading = '',
  heroImage = '',
  page = '',
  imgSrc = '',
  onClick = () => {},
}) => {
    return (
    <div
      className={
        ConcatClasses([
          className,
          'hero--wrapper',
        ])
      }
      style={{ backgroundImage: "url(" + heroImage + ")" }}
    >
        { children }
        { heading ? (
              <div
                  className={ConcatClasses([
                    'hero--header',
                    'mar--0-auto' ,
                    'mar--b-10p',
                    page === 'about'? 'mar--t-76' : 'mar--t-10p',
                    'mar--lrg-t-0',
                    'pointer--none',
                    'width--75-p',
                    'width--med-50-p',
                  ])}
              >
                  <Image
                      alt={heading}
                      className={ConcatClasses([
                        'mh--185',
                        page === 'home' || page === 'faqs' ? 'mar--t-45' : '',
                      ])}
                      src={imgSrc}
                  />
              </div>
        ) : ''}
    </div>
  );
}
export default Hero;