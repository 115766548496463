import React from 'react';

import ConcatClasses from '../../utils/ConcatClasses';
import SiteContent from '../../json/SiteContent';

import Text from './Text';
// import './footer.scss';

const Footer = ({
  className = '',
  data = {},
}) => {
  const today = new Date();
  const year = today.getFullYear();
  const footerData = SiteContent[0].footer;

  return (
    <footer
      className={
        ConcatClasses([
          className,
          'footer--wrapper',
          'mar--lrg-l-100',
        ])
      }
    >

      <Text
        className={
          ConcatClasses([
            'heading--4',
            'text--grey',
            'mar--0-auto',
            'mw--1045-p',
          ])
        }
      >
        ©{year} {footerData.copy}
      </Text>
    </footer>
  );
}

export default Footer;
