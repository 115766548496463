import React from 'react';


const FourZeroFour = () => {
  return (
    <section
      className="bg--yellow mar--t-50 text--center mh--700 mh-med-1000 flex"
    >
      <p className="mar--t-100">404</p>
    </section>
  );
}

export default FourZeroFour;
