import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import './_icon.scss';

const Icon = ({
    className = '',
}) => {
    return (
        <i 
            className={
                ConcatClasses([
                    'icon',
                    className,
                ])
            }
        />
    );
}

export default Icon;