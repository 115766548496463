import React from 'react';

import ConcatClasses from '../../utils/ConcatClasses';

import Text from './Text';
import LinkComp from './Link';
// import './terms.scss';

import SiteContent from '../../json/SiteContent';

const Terms = ({
  className = '',
  data = {},
}) => {
    const footerData = SiteContent[0].footer;
    const linkColumnOne = footerData.links[0];
    const linkColumnTwo = footerData.links[1];

    const columnOne = linkColumnOne.row.map((link) => {
        const {
            color,
            heading,
            href,
            title,
        } = link;

        return (
            <LinkComp
                className={
                    ConcatClasses([
                        'dis--block',
                    ])
                }
                key={title}
                href={href}
            >
                <Text
                    className={
                        ConcatClasses([
                            `heading--${heading}`,
                            `text--${color}`,
                            'mar--b-10',
                        ])
                    }
                >
                    {title}
                </Text>
            </LinkComp>
        );
    });

    const columnTwo = linkColumnTwo.row.map((link) => {
        const {
            color,
            download,
            heading,
            href,
            title,
            target,
        } = link;

        if (target === 'email') {
            return (
                <a 
                    key={title}
                    href={href}
                    className={
                        ConcatClasses([
                            'link',
                            'dis--block',
                            'mar--b-10',
                            `heading--${heading}`,
                            'link--lrg-arrow',
                            `link--${color}`,
                        ])
                    }
                    target={target}
                >
                    {title}
                    <span className='arrow'/>
                </a>
            )
        }

        return (
            <a
                download={download}
                className={
                    ConcatClasses([
                        'link',
                        'dis--block',
                        'mar--b-10',
                        `heading--${heading}`,
                        'link--lrg-arrow',
                        `link--${color}`,
                    ])
                }
                key={title}
                href={href}
                target={target}
            >
                
                {title}
                <span className='arrow'/>
            </a>
        );
    });

    return (
        <div
            className={
                ConcatClasses([
                    className,
                    'bor--lrg-t-blue-6',
                    'footer--wrapper',
                ])
            }
        >
            <div
                className={
                    ConcatClasses([
                        'mar--lrg-l-100',
                        'vis--hidden',
                        'vis--lrg-visible',
                    ])
                }
            >
                <div
                    className={
                        ConcatClasses([
                            'flex',
                            'mar--0-auto',
                            'mw--1045-p',
                            'pad--t-10',
                            'text--left',
                            'width--90-p',
                        ])
                    }
                >
                    <div
                        className={
                            ConcatClasses([
                                'width--33-p',
                            ])
                        }
                    >
                        {columnOne}
                    </div>
                    <div
                        className={
                            ConcatClasses([
                                'width--33-p',
                            ])
                        }
                    >
                        {columnTwo}
                    </div>
                </div>
            </div>

            <LinkComp
                className={
                    ConcatClasses([
                        'vis--lrg-hidden',
                    ])
                }
                href='/terms'
                target='_blank'
            >
                <Text
                    className={
                        ConcatClasses([
                        className,
                        'text--black',
                        ])
                    }
                >
                    Terms and conditions
                </Text>
            </LinkComp>
            <a
                className={
                    ConcatClasses([
                        'vis--lrg-hidden',
                    ])
                }
                target="_self"
                download="WIP_OpeningShot_PrivacyPolicy_4.11.2022.pdf"
                href={linkColumnTwo.row[2].href}
            >    
                <Text
                    className={
                        ConcatClasses([
                        className,
                        'text--black',
                        ])
                    }
                >
                Privacy Policy
                </Text>                    
            </a>
        </div>
    );
}

export default Terms;
