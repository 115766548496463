import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import './_image.scss';

const Image = ({
    alt = 'image',
    className = '',
    src = '',
}) => {

    return (
        <img
            className={ConcatClasses([
                className,
            ])}
            alt={alt}
            src={src}
        />
    );
}

export default Image;