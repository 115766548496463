import React, { useState } from 'react';

import ConcatClasses from '../../utils/ConcatClasses';
import { Link } from 'react-router-dom';

import './_menu.scss';

const Menu = ({
    className = '',
    content = '',
}) => {
    const {
        social,
    } = content;

    const menuItem = [
        {
            href: '/',
            name: 'Home',
        },
        {
            href: '/about',
            name: 'About',
        },
        {
            href: '/roles',
            name: 'Roles',
        },
        {
            href: '/apply',
            name: 'Apply',
        },
        {
            href: '/faqs',
            name: 'FAQs',
        }
    ]
    const [checkboxChecked, setCheckbox] = useState(false);
    const closeMenu = (e) => {
        setCheckbox(false);
    }
    const handleCheckbox = (e) => {
        const toggle = !checkboxChecked;
        setCheckbox(toggle);
    }

    const renderMenuList = menuItem.map((item) => {
        const {
            href,
            name,
        } = item;

        return (
            <Link
                className={ConcatClasses([
                    'flex',
                    'item',
                    'justify-content--center',
                    // 'mar--b-10',
                    // 'mar--t-10',
                    'mar--t-b-5p',
                    'text--black',
                ])}
                key={name}
                to={href}
                onClick={(e) => closeMenu(e)}
            >
                <span>
                    {name}
                </span>
            </Link>
        );
    });

    const renderSocial = social.map((social) => {
        const {
            link,
            name,
            imageUrl,
        } = social;

        return (
            <a
                key={name}
                className={ConcatClasses([
                    'mar--l-15',
                    'mar--r-15',
                    'menu--social',
                    'pointer--auto',
                ])}
                href={link}
                style={{ backgroundImage: "url(" + imageUrl  + ")" }}
                target="_blank"
            >
                {name}
            </a>
        )
    });

    // console.log('CONTENT   ', content);
    return (
        <div
            className={
                ConcatClasses([
                    className,
                    'menu--wrapper',
                ])
            }
        >
            <input 
                type="checkbox"
                id="toggle"
                className={ConcatClasses([
                    'vis--hidden',
                    'pos--abs',
                ])}
                onChange={(e) => handleCheckbox(e)}
                checked={checkboxChecked}
            />
            <label 
                htmlFor="toggle"
                className={ConcatClasses([
                    'bars',
                    'flex',
                    'pointer--cursor',
                    'justify-content--center',
                    'mar--0-auto',
                    'mar--t-10',
                    'text--black',
                ])}
                >
                    <span>Menu</span>
                    <i />
                </label>
            <div className="menu--items">
                <div
                    className="nav--wrapper"
                >
                    {renderMenuList}
                </div>
                
                <div
                    className={ConcatClasses([
                        'social--wrapper',
                        'text--center',
                    ])}
                >
                    {renderSocial}
                </div>
            </div>            
        </div>
  );
}

export default Menu;
