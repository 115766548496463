import React from 'react';

import ConcatClasses from '../../utils/ConcatClasses';

import './_input.scss';

const Input = ({
    className = '',
    onBlur = () => {},
    placeHolder = '',
    min = null,
    max = null,
    maxLength=null,
    name = '',
    onChange = () => {},
    type = '',
    required = false,
    pattern = null,
    value = undefined,
}) => {

  return (
    <input
      className={ConcatClasses([
        className,
      ])}
      min={min}
      max={max}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeHolder}
      pattern={pattern}
      required
      type={type}
      value={value}
    />
  );
}

export default Input;
