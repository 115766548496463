import React, { useState } from 'react';

export const Context = React.createContext();

const Provider = (props) => {
  const [
    currentState,
    setNewState,
  ] = useState(
    {
      providerState: 'Provider data',
    }
  );

  return (
    <Context.Provider
      value={{
        currentState,
        setNewState,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default Provider;
