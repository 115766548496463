import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import LinkComp from './Link';
import Image from './Image';
import Text from './Text';


// import './_pledge.scss';

const Pledges = ({
  data = {},
  callToAction = {},
  className = '',
  companies = [],
  copy = '',
  links = [],
  heading = '',
}) => {
  const {
    alt: ctaAlt,
    url: ctaUrl,
  } = callToAction;

  const renderPledges = companies.map((company) => {
    const {
      logo: {
        alt,
        url,
      }
    } = company;

    console.log('links', links)


    return (
      <div
        key={alt}
        className={
            ConcatClasses([
                'dis--inline-block',
                'mar--b-20',
                'pad--10',
                'width--30-p',
        ])}
      >
        <Image
            className={
              ConcatClasses([
                  'dis--inline-block',
          ])}
          key={alt}
          alt={alt}
          src={url}
        />
      </div>
    )
  })

  return (
    <div
      className={
        ConcatClasses([
          className,
          'pledges--wrapper',
        ])
      }
    >
      <div
        className={
          ConcatClasses([
            'text--center',
          ])
        }
      >
        <Image
            alt={heading}
            className={ConcatClasses([
              'mar--0-auto',
              'mar--lrg-b-0',
              'mh--185',
              'mw--500-p',
              'width--75-p',
            ])}
            src={ctaUrl}
        />
        <Text
          className={ConcatClasses([
            'mw--500-p',
            'text--left',
            'width--75-p',
          ])}
        >
          {copy}
        </Text>
        <div
          className={ConcatClasses([
            'dis--block',
          ])}
        >
          <a
            className={
              ConcatClasses([
                'link--border link arrow border button link link--black mar--t-20 text--uppercase',
                'border',
                'button',
                'link',
                'link--black',
                'mar--t-20',
                'text--uppercase',
              ])
            }
            href={links[0].link.href}
          >
            {links[0].link.text}
            <i className="arrow" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Pledges;
