import React from 'react';
import { useMediaQuery } from 'react-responsive';

// import Reactsection from '@section/react-section';
import ConcatClasses from '../../utils/ConcatClasses';
import IsTouchClasses from '../../utils/IsTouchClasses';

import Art from '../simple/Art';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../simple/Footer';
import Terms from '../simple/Terms';

import Pledges from '../simple/Pledges';
import Hero from '../simple/Hero';

import LinkComp from '../simple/Link';
import Text from '../simple/Text';
import Video from '../smart/Video';

import './_home.scss';
import './_fullpage.scss';

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
    require('../extensions/fullpage.parallax.min');
};

const Home = ({
    content = {},
    pledge = {}
}) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    const renderHowItWorksList = () => {
        return (
            <div
                className={ConcatClasses([
                    'bor--white-l-1',
                    'dis--inline-block',
                    'mar--lrg-t-80',
                    'pad--l-70',
                    'width--lrg-33-p',
                    'vis--hidden',
                    'vis--lrg-visible',
                ])}
                >
                <Text
                    className={ConcatClasses([
                        'heading--3',
                        "mar--b-20",
                        'text--yellow',
                    ])}
                >
                    How it works
                </Text>
                
                <ul
                    className={ConcatClasses([
                        'list--number',
                        'text--white',
                    ])}
                >
                    <li className="mar--b-20">
                        <LinkComp
                            className={ConcatClasses([
                                'link--white',
                            ])}
                            href='/roles'
                        >
                            <span className="dot">1</span>
                            Find Your Fit
                        </LinkComp>
                    </li>
                    <li className="mar--b-20">
                        <LinkComp
                            className={ConcatClasses([
                                'link--white',
                            ])}
                            href='/apply'
                        >
                            <span className="dot">2</span>
                            Apply
                        </LinkComp>
                    </li>
                    <li className="mar--b-20">
                        <LinkComp
                            className={ConcatClasses([
                                'link--white',
                            ])}
                            href='/roles'
                        >
                        <span className="dot">3</span>
                            Get On Set
                        </LinkComp>
                    </li>
                </ul>
            </div>
        )
    }


    const renderHomeSections = ({state, fullpageApi}) => {
        const {
            hero,
            sectionOne,
            sectionTwo,
            sectionThree,
            sectionFour,
        } = content;
    
        const {
            companies,
            callToAction,
        } = pledge;
    
        const createMarkup = () => {
            return {__html: hero.copy};
        }

        if(fullpageApi) {
            fullpageApi.destroy('all');
        }

        return (
            <div
                className={
                    ConcatClasses([
                        "bg--black",
                        "height--lrg-100-p",
                        "home--page",
                        "width--lrg-100-p",
                    ])
                }
            >
              <section
                className={
                    ConcatClasses([
                        'section',
                    ])
                }
              >
                <div
                    className={
                        ConcatClasses([
                            'pos--rel',
                        ])
                    }
                >
                    <div
                        className={
                            ConcatClasses([
                                isDesktopOrLaptop ? 'pos--abs' : 'pos--abs',
                                'video--wrapper',
                                'z--0',
                            ])
                        }
                    >
                        {IsTouchClasses()? (
                            <div
                                className={
                                    ConcatClasses([
                                        'hero--wrapper',
                                        'mh--300',
                                    ])
                                }
                                style={{ backgroundImage: "url(" + hero.videoBg  + ")" }}
                            />
                        ) : (
                            <Video
                                IsTouchClasses
                                className={
                                    ConcatClasses([
                                        'hero--vid',
                                        'pointer--none',
                                        'pos--abs',
                                        'width--100-p',
                                        'z--0',
                                    ])
                                }
                                videoLink={hero.video}
                            />
                        )}
    
                    </div>
                    <Hero
                        className={ConcatClasses([
                            'mar--lrg-b-0',
                        ])}
                        copy={hero.copy}
                        heading={hero.heading}
                        imgSrc={hero.headingImage}
                        link={true}
                        page="home"
                    />
                    <div
                        className={ConcatClasses([
                            'bg--black',
                            'mar--lrg-b-10p',
                        ])}
                    >
                        <div
                            className={ConcatClasses([
                                'dis--inline-block',
                                'pad--lrg-l-r-80',
                                'ver-align--t',
                                'width--100-p',
                                'width--lrg-66-p',
                            ])}
                        >
                            {hero.copy ? (
                                <div
                                    className={ConcatClasses([
                                        'pad--t-40',
                                        'pad--lrg-0',
                                        'pad--lrg-t-80',
                                        'pad--lrg-l-80',
                                        'mar--0-auto',
                                        'text--white',
                                        'width--90-p',
                                        'width--lrg-75-p',
                                    ])}
                                >
                                    <div dangerouslySetInnerHTML={createMarkup()} />
                                    {isDesktopOrLaptop ? (
                                        <LinkComp
                                            arrow
                                            className={ConcatClasses([
                                                'heading--3',
                                                'link--lrg-arrow',
                                                'link--white',
                                                'float--lrg-r',
                                                'mar--t-30',
                                                'text--center',
                                                'vis--hidden',
                                                'vis--lrg-visible',
                                            ])}
                                            href="/apply"
                                        >                        
                                            Apply
                                        </LinkComp>
                                    ) : ''}
                                </div>
                            ) : ''}
                            <div
                                className={ConcatClasses([
                                    'mar--t-20',
                                    'mar--b-40',
                                    'pad--lrg-l-r-80',
                                    'text--center',
                                    'text--lrg-left',
                                ])}
                            >
                                <LinkComp
                                    arrow
                                    className={ConcatClasses([
                                        'heading--3',
                                        'link--lrg-arrow',
                                        'link--white',
                                        'text--center',
                                        'vis--lrg-hidden',
                                    ])}
                                    href="/apply"
                                >                        
                                    Apply
                                </LinkComp>
                            </div>
                        </div>

                        {isDesktopOrLaptop ? (
                            renderHowItWorksList()
                        ) : ''}
                    </div>
                </div>
              </section>
    
              <section
                  className={
                      ConcatClasses([
                          'bg--white',
                          'section',
                          'xx--t-l',
                      ])
                  }
              >
                  <div
                      className={ConcatClasses([
                          'pad--50',
                          'text--center',
                      ])}
                  >
                      <Text
                        className={ConcatClasses([
                            'heading--lrg-0',
                            'heading--1',
                            'text--uppercase',
                        ])}
                      >
                        {'How it works'}
                      </Text>
                  </div>
                  <Art
                    align="right"
                    bg='light'
                    className={
                        ConcatClasses([
                            'art--t-r',
                            'mh--med-1000',
                            'mh--lrg-750',
                            'ovf--hidden',
                            'pad--t-30',
                        ])
                    }
                    copyBg='blue'
                    copy={sectionOne.copy}
                    heading={sectionOne.heading}
                    imgSrc={sectionOne.art}
                    link={sectionOne.link}
                    number={sectionOne.number}
                  />
              </section>
              <section
                  className={
                      ConcatClasses([
                          'bg--yellow',
                          'section',
                          'ovf--x-hidden',
                      ])
                  }
              >
                <Art
                    align="center"
                    bg='light'
                    className={
                        ConcatClasses([
                            'art--t-r',
                            'mar--lrg-l-100',
                            'mh--med-700',
                            'ovf--x-hidden',
                            'ovf--lrg-visible',
                        ])
                    }
                    copyBg='black'
                    copy={sectionTwo.copy}
                    heading={sectionTwo.heading}
                    imgSrc={sectionTwo.art}
                    link={sectionTwo.link}
                    number={sectionTwo.number}
                />
              </section>        
    
              <section
                  className={
                      ConcatClasses([
                          'bg--black',
                          'ovf--x-hidden',
                          'ovf--lrg-visible',
                          'section',
                          'xx--t-r-yellow',
                      ])
                  }
              >
                <Art
                    align="left"
                    bg='dark'
                    className={
                        ConcatClasses([
                            'art--t-r',
                            'mh--med-700',
                            'ovf--hidden',
                            'ovf--lrg-visible',
                        ])
                    }
                    copyBg='white'
                    copy={sectionThree.copy}
                    heading={sectionThree.heading}
                    imgSrc={sectionThree.art}
                    link={sectionThree.link}
                    number={sectionThree.number}
                />
              </section>
              <section
                  className={
                    ConcatClasses([
                        'bg--white',
                        'ovf--x-hidden',
                        'section',
                    ])
                }
              >
                <Pledges
                  className={
                      ConcatClasses([
                          'text--black',
                          'mar--b-45',
                          'mar--lrg-b-235',
                          'mar--lrg-l-100',
                      ])
                  }
                  companies={companies}
                  callToAction={callToAction}
                  copy={sectionFour.copy}
                  heading={sectionFour.heading}
                  links={sectionFour.links}
                />
                <div
                    className={
                        ConcatClasses([
                            'btm--lr',
                            'pos--lr-abs',
                            'width--100-p',
                        ])
                    }
                >
                    <Terms 
                        className={
                            ConcatClasses([
                                'bg--yellow',
                                'bg--lrg-black',
                                'text--center',
                                'pad--b-10',
                                'pad--t-10',
                            ])
                        }
                    />
                    <Footer
                        className={
                            ConcatClasses([
                                'bg--white',
                                'bg--lrg-black',
                                'pad--b-20',
                                'pad--t-20',
                                'text--center',
                                'text--lrg-left',
                            ])
                        }
                    />
                </div>
              </section>
            </div>
        );
    }

    return (
        <div>
            {renderHomeSections({ state: null, fullpageApi: null })}
        </div>
    )
}

export default Home;
