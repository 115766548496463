import React from 'react';
import Swipe from 'react-easy-swipe';


import ConcatClasses from '../../utils/ConcatClasses';

// import './about.scss';

import Hero from '../simple/Hero';
import Footer from '../simple/Footer';
import LinkComp from '../simple/Link';
import Terms from '../simple/Terms';
import Text from '../simple/Text';


const About = ({
  content= {
    hero: {
      copy: '',
    },
    },
}) => {
  const {
    hero,
    sectionOne,
  } = content;

  const onSwipeStart = (event) => {
    console.log('Start swiping...', event);
  }
  
  const onSwipeMove = (position, event) => {
      console.log(`Moved ${position.x} pixels horizontally`, event);
      console.log(`Moved ${position.y} pixels vertically`, event);
  }

  const onSwipeEnd = (event) => {
      console.log('End swiping...', event);
  }

  return (
    <Swipe
      onSwipeStart={(e) => onSwipeStart(e)}
      onSwipeMove={(e) => onSwipeMove(e)}
      onSwipeEnd={(e) =>  onSwipeEnd(e)}
    >

    
    <section
      className={ConcatClasses([
        'bg--black',
        'apply--section',
        'xx--r-b',
      ])}
    >
      <Hero
        className={ConcatClasses([
          'flex',
          'justify-content--center',
          'mh--300',
          'mh--lrg-700',
        ])}
        heroImage={hero.heroImage}
        heading={hero.heading}
        imgSrc={hero.headingImage}
        page='about'
      />
      <div
        className={
          ConcatClasses([
            'bg--white',
            'pad--50',
        ])}
      >
        <div
          className='cir-yellow--r-c'
        >
          <div
            className={
              ConcatClasses([
                'bg--blue',
                'pad--50',
                'mar--0-auto',
                'mw--500-p',
                'z--1',
            ])}
          >
            <Text
              className={
                ConcatClasses([
                  'text--white'
              ])}
            >
              {sectionOne.copy}
            </Text>
            <div
              className={ConcatClasses([
                'vis--hidden',
                'vis--lrg-visible',
              ])}
            >
              <LinkComp
                arrow
                className={ConcatClasses([
                  'dis--block',
                  'heading--3',
                  'link--lrg-arrow',
                  'link--white',
                  'mar--t-30',
                  'text--center',
                  'text--right',
                ])}
                href="/apply"
              >                        
                  Apply
              </LinkComp>
              <br />
              <LinkComp
                  arrow
                  className={ConcatClasses([
                    'dis--block',
                    'heading--3',
                    'link--lrg-arrow',
                    'link--white',
                    'mar--t-30',
                    'text--center',
                    'text--right',
                  ])}
                  href="/pledge"
              >                        
                  PLEDGE A PRODUCTION
              </LinkComp>
            </div>
          </div>
        </div>
      </div>
      <Terms 
        className={
            ConcatClasses([
                'bg--yellow',
                'bg--lrg-black',
                'text--center',
                'pad--b-10',
                'pad--t-10',
            ])
        }
      />
      <Footer
        className={
          ConcatClasses([
              'bg--white',
              'bg--lrg-black',
              'pad--b-20',
              'pad--t-20',
              'text--center',
              'text--lrg-left',
          ])
        }
      />
    </section>
    </Swipe>
  );
}

export default About;

