import React, { useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

import { useMediaQuery } from 'react-responsive'
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import SiteContent from '../../json/SiteContent';

import ConcatClasses from '../../utils/ConcatClasses';
import Hero from '../simple/Hero';
import Role from '../simple/Role';
import Footer from '../simple/Footer';
import Terms from '../simple/Terms';

import './_roles.scss';
import './_fullpage.scss';

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
  require('../extensions/fullpage.parallax.min');
};

const Roles = () => {
  const siteContent = SiteContent[0].roles;

  const {
    hero,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
  } = siteContent;

  useEffect(() => {
    console.log("USE EFFECT ")
  }, []);

  let firstClick = false;

  // MEDIA QUERIES CHECK
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  
  const renderRoles = ({ state, fullpageApi }) => {
    const links = [
      {
        'href': "#copy-writter",
        'title': "Copy Writer",
        'section': 4,
      },
      {
        'href': "#art-director",
        'title': "Art Director",
        'section': 2,
      },
      {
        'href': "#producer",
        'title': "Producer",
        'section': 3,
      },
      {
        'href': "#account-manager",
        'title': "Account Manager",
        'section': 5,
      }
    ]

    const handleClick = (e, fullpageApi, section) => {

      if(!firstClick) {
        // e.preventDefault();
        console.log('E TARGET ', e.target)
        // e.target.click();
        if(fullpageApi) {
          fullpageApi.destroy('all');
        }
        fullpageApi.moveTo(section)
      }
    }
  
    const renderLinks = links.map((link) => {
      const {
        href,
        section,
        title,
      } = link;

      return (
        <a
          key={href}
          className='pointer--cursor'
          href={href}
          target='_self'
        >

          {title}
        </a>
      )
    })
  
    return (
      <div
          className={
              ConcatClasses([
                  'bg--black',
                  'height--lrg-100-p',
                  'width--lrg-100-p',
              ])
          }
        >
          <section
            data-anchor="hero"
            className={
                ConcatClasses([
                    "section",
                ])
            }
          >
            <Hero
              className={ConcatClasses([
                'scale--none',
                'roles-page--hero',
                'flex',
                'justify-content--center',
                'mar--lrg-b-400',
              ])}
              heroImage={hero.heroImage}
              heading={hero.heading}
              imgSrc={hero.headingImage}
            >

              <div
                className={ConcatClasses([
                  'link--wrapper',
                  'link--white',
                ])}
              >
                {renderLinks}
              </div>  
            </Hero>
          </section>
          <section
            id="art-director"
            data-anchor="art-director"
            className={
              ConcatClasses([
                'bg--yellow',
                'section',
              ])
            }
          >
            <Role
              align='right'
              bg='light'
              className={
                ConcatClasses([
                  'art--t-r',
                  'mh--700',
                  'ovf--hidden',
                ])
              }
              copyBg='black'
              copy={sectionOne.copy}
              heading={sectionOne.heading}
              imgSrc={sectionOne.art}
              link={sectionOne.link}
              number={sectionOne.number}
            />
          </section>
          <section
            id="producer"
            data-anchor="producer"
            className={
              ConcatClasses([
                'bg--blue',
                'section',
              ])
            }
          >
            <Role
              align='left'
              bg='white'
              className={
                ConcatClasses([
                  'art--t-r',
                  'mh--700',
                  'ovf--hidden',
                  'ovf--lrg-visible',
                ])
              }
              copyBg='white'
              copy={sectionThree.copy}
              heading={sectionThree.heading}
              imgSrc={sectionThree.art}
              link={sectionThree.link}
              number={sectionThree.number}
              shape='yellow--rec-border'
            />
          </section>
          <section
            id="copy-writter"
            data-anchor="copy-writter"
            className={
              ConcatClasses([
                'bg--yellow',
                'section',
              ])
            }
          >
            <Role
              align='right'
              bg='light'
              className={
                ConcatClasses([
                  'art--t-r',
                  'mh--700',
                  'ovf--hidden',
                  'ovf--lrg-visible',
                ])
              }
              copyBg='black'
              copy={sectionFour.copy}
              heading={sectionFour.heading}
              imgSrc={sectionFour.art}
              link={sectionFour.link}
              number={sectionFour.number}
              shape='cir-white--r-b'
              tall={true}
            />
          </section>
          <section
              id="account-manager"
              data-anchor="account-manager"
              className={
                ConcatClasses([
                  'bg--white',
                  'section',
                ])
              }
            >
              <Role
                align='left'
                bg='light'
                className={
                  ConcatClasses([
                    'art--t-r',
                    'mh--700',
                    'ovf--hidden',
                    'ovf--lrg-visible',
                    'pad--lrg-b-7p',
                  ])
                }
                copyBg='blue'
                copy={sectionFive.copy}
                heading={sectionFive.heading}
                imgSrc={sectionFive.art}
                link={sectionFive.link}
                number={sectionFive.number}
                shape='cir-yellow--l'
              />
              <div
                className={
                    ConcatClasses([
                        'btm--lr',
                        'pos--lr-abs',
                        'width--100-p',
                    ])
                }
              >
                  <Terms 
                      className={
                          ConcatClasses([
                              'bg--yellow',
                              'bg--lrg-black',
                              'text--center',
                              'pad--b-10',
                              'pad--t-10',
                          ])
                      }
                  />
                  <Footer
                      className={
                          ConcatClasses([
                              'bg--white',
                              'bg--lrg-black',
                              'pad--b-20',
                              'pad--t-20',
                              'text--center',
                              'text--lrg-left',
                          ])
                      }
                  />
              </div>
          </section>
        </div>
      );
  }

  return (
      <div>
          {renderRoles({ state: null, fullpageApi: null })}
      </div>
  )
}

export default Roles;



