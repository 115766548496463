import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

import Provider from './context/Provider';

import AppRouter from './components/smart/AppRouter';
import Menu from './components/smart/Menu';
import ConcatClasses from './utils/ConcatClasses';
// import IsTouchClasses from './utils/IsTouchClasses';
import ScrollToTop from './utils/ScrollToTopClasses';

import SiteContent from './json/SiteContent';

import "./assets/sass/main.scss";

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const siteContent = SiteContent[0];

  return (
    <div
      className={
        ConcatClasses([
          'app--wrapper',
          'ovf--hidden',
          isDesktopOrLaptop ? 'large' : 'medium',
          isPortrait ? 'portrait': 'landscape',
          isRetina ? 'high-res' : 'low-res',
        ])
      }
    >
      <Router>
        <ScrollToTop>
          <Provider>
              <Menu
                className={
                  ConcatClasses([
                    'z--1000',
                  ])
                }
                content={siteContent.menu}
              />
              <AppRouter />
          </Provider>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;



