import React, { Fragment } from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import Footer from '../simple/Footer';
import Hero from '../simple/Hero';

import Terms from '../simple/Terms';
import Text from '../simple/Text';

// import './faqs.scss';

const Faqs = ({
  content = {},
}) => {

  const {
    hero,
    questions,
  } = content;
  const renderQuestions = (questions) => {
    return (
      <Fragment>
        <div
          className={ConcatClasses([
            'bg--black',
            'flex--lrg',
            'mar--lrg-l-105',
          ])}
        >
          
          <div
            className={ConcatClasses([
              'bg--black',
              'dis--inline-block',
              'pad--50',
              'pad--t-30',
              'pad--lrg-80',
              'width--lrg-50-p',
            ])}
          >
            <Text
              className={ConcatClasses([
                'mw--340-p',
                'text--white',
              ])}
            >
              <span
                className={ConcatClasses([
                  'text--black',
                  'text--yellow',
                ])}
              >
                {questions[0].title}
              </span>&nbsp;
              {questions[0].question}
            </Text>
          </div>

          <div
            className={ConcatClasses([
              'bg--white',
              'dis--inline-block',
              'pad--50',
              'pad--t-30',
              'pad--lrg-80',
              'width--lrg-50-p',
            ])}
          >
            <Text
              className={ConcatClasses([
                'mw--340-p',
              ])}
            >
              <span
                className={ConcatClasses([
                  'text--black',
                  'text--blue',
                ])}
              >
                {questions[1].title}
              </span>&nbsp;
              {questions[1].question}
            </Text>
          </div>
        </div>
        <div
          className={ConcatClasses([
            'bg--yellow',
            'mar--lrg-l-105',
            'pad--50',
            'pad--t-30',
            'pad--lrg-80',
            'xx--t-l',
          ])}
        >
          <Text
            className={ConcatClasses([
              'mw--340-p',
            ])}
          >
            <span
                className={ConcatClasses([
                  'text--black',
                ])}
              >
                {questions[2].title}
            </span>&nbsp;
            {questions[2].question}
          </Text>
        </div>
      </Fragment>
    )
  }

  return (
    <section
        className={ConcatClasses([
          "bg--yellow",
      ])}
    >
      <Hero
        className={ConcatClasses([
          'flex',
          'justify-content--center',
          'mh--300',
          'mh--lrg-700',
        ])}
        heroImage={hero.heroImage}
        heading={hero.heading}
        imgSrc={hero.headingImage}
        page="faqs"
      />
      {renderQuestions(questions)}
      {renderQuestions([questions[3], questions[4], questions[5]])}
      {renderQuestions([questions[6], questions[7], questions[8]])}
      {renderQuestions([questions[9], questions[10], questions[11]])}
      <div
          className={
              ConcatClasses([
                  // 'btm--lr',
                  // 'pos--lr-abs',
                  'width--100-p',
              ])
          }
      >
          <Terms 
              className={
                  ConcatClasses([
                      'bg--yellow',
                      'bg--lrg-black',
                      'text--center',
                      'pad--b-10',
                      'pad--t-10',
                  ])
              }
          />
          <Footer
              className={
                  ConcatClasses([
                      'bg--white',
                      'bg--lrg-black',
                      'pad--b-20',
                      'pad--t-20',
                      'text--center',
                      'text--lrg-left',
                  ])
              }
          />
      </div>
    </section>
  );
}

export default Faqs;
