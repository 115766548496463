import React, { useState } from 'react';
import ConcatClasses from '../../utils/ConcatClasses';
import axios from 'axios';

import Checkbox from '../simple/Checkbox';
import Form from '../smart/Form';
import Footer from '../simple/Footer';
import Image from '../simple/Image';
import Input from '../simple/Input';
import States from '../simple/States';
import Select from '../simple/Select';
import Terms from '../simple/Terms';
import FormNav from '../simple/FormNav';

import Text from '../simple/Text';

// import './pledges.scss';

const Pledge = ({
  content = {},
}) => {
  // hooks
  const [curSection, setSection] = useState(1);
  const [pledgeInputData, setPledgeData] = useState({});
  const [formOne, setFormOne] = useState([null, null, null, null, null, null]);
  const [nextButton, setNextButton] = useState(null);

  // Defaults
  const {
    error,
    pageHeading,
    hero,
    success,
  } = content;
  const inputFieldsArrayOne = [
    {
      name: 'name',
      placeHolder: "NAME OF BRAND OR AGENCY",
    },
    {
      name: 'number-of-productions',
      placeHolder: "# OF PRODUCTIONS PLEDGING",
    },
    {
      name: 'brand-or-agency',
      placeHolder: "BRAND OR AGENCY?",
    },
    {
      name: 'website',
      placeHolder: "WEBSITE LINK",
    },
    {
      name: 'city',
      placeHolder: "CITY",
    },
    {
      name: 'state',
      placeHolder: "STATE",
    }
  ];
  const inputFieldsArrayTwo = [
    {
      name: 'contact-agency-firstname',
      placeHolder: "CONTACT AT AGENCY, FIRST NAME",
    },
    {
      name: 'contact-agency-lastname',
      placeHolder: "CONTACT AT AGENCY, LAST NAME",
    },
    {
      name: 'average-number-of-productions-each-year',
      placeHolder: "AVERAGE # OF PRODUCTIONS EACH YEAR",
    }
  ];
  const formPages = [
    {
      section: "one"
    },
    {
      section: "two"
    }
  ]
  const hanldeInput = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    console.log('INPUT VALUE', value, name)

    setPledgeData({...pledgeInputData, [name]: value});
  }

  // validate every input per section
  const validateAllInput = () => {
    const every = formOne.every((item) => item === true);
    console.log('FORM ONE -----------', formOne);
    console.log(every);

    if(every) {
      setNextButton(true);
    } else {
      setNextButton(false);
    }
  }

  const handleValidation = (e, index) => {
    const valid = e.target.checkValidity();
    const tempArray = formOne;
    tempArray[index] = valid;

    console.log('TARGET', e.target , valid);

    console.log(index, valid)
    setFormOne(tempArray);
    validateAllInput(formOne);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const inputData = {
      ...pledgeInputData,
    }
    const url = 'https://openingshot.org/agency-submission/';

    const formData = new FormData();
    formData.set('data', JSON.stringify(inputData));

    console.log('COMBINE IN PUT DATA :', inputData);
    axios.post(url, formData)
    .then(function (response) {
      setSection(200);
      console.log('HANDLE SUCESS', response);

    })
    .catch(function (error) {
      setSection(200);
      console.log('HANDLE ERROR ', error);
    });

    console.log('SUBMIT PLEDGE INPUT DATA   ', pledgeInputData)
  }
  const renderInputField = (mapArray) => {
    const inputs =  mapArray.map((input, index) => {
        const {
          name,
          placeHolder,
        } = input;

        if (name === 'brand-or-agency') {
          return (
            <Select 
              className={ConcatClasses([
                'dis--block',
                'select--black',
                'mar--b-40',
                'width--100-p',
              ])}
              key={placeHolder}
              name={name}
              onBlur={(e) => handleValidation(e, index)}
              onChange={(e) => hanldeInput(e)}
              placeHolder={placeHolder}
            />
          )
        }

        if (name === 'state') {
          return (
            <States 
              className={ConcatClasses([
                'dis--block',
                'select--black',
                'mar--b-40',
                'width--100-p',
              ])}
              key={placeHolder}
              name={name}
              onBlur={(e) => handleValidation(e, index)}
              onChange={(e) => hanldeInput(e)}
              placeHolder={placeHolder}
            />
          )
        }
    
        return (
          <Input
            className={ConcatClasses([
              'dis--block',
              'input--black',
              'mar--b-40',
              'width--100-p',
            ])}
            key={placeHolder}
            name={name}
            onBlur={(e) => handleValidation(e, index)}
            onChange={(e) => hanldeInput(e)}
            placeHolder={placeHolder}
            type='text'
          />
        )
      });
    return inputs;
  }

  return (
    <div
      className={ConcatClasses([
        'xx--t-r',
        'bg--yellow',
      ])}
    >
      <section
        className={ConcatClasses([
            'mh--lrg-750',
            'mar--0-auto',
            'pad--t-100',
            'text--center',
            'width--90-p',
        ])}
      >
        {curSection === 200 ? (
          <div>
            <Text
              className={ConcatClasses([
                'heading--1',
                'mar--b-30',
                'mar--lrg-b-80',
                'text--black',
              ])}
            >
              {pageHeading}
            </Text>
          </div>
        ) : ''}
        <Image
            alt={hero.alt}
            className={ConcatClasses([
              'dis--inline-block',
              'vis--lrg-hidden',
              'mh--185',
            ])}
            src={hero.headingImageSml}
        />
        
        <Form
          className={ConcatClasses([
            'dis--lrg-inline-block',
            'mw--lrg-370-p',
            'pad--b-40',
            'pad--lrg-r-50',
            'width--lrg-50-p',
          ])}
          onSubmit={(e) => handleFormSubmit(e)}
        >
          <div
            className={ConcatClasses([
              '',
            ])}
          > 
            {/* SLIDE ONE */}
            <div
              className={ConcatClasses([
                curSection === 1 ? 'transition--in' : 'transition--out',
              ])} 
            >
              {renderInputField(inputFieldsArrayOne)}
            </div>
            
            {/* SLIDE TWO */}
            <div
              className={ConcatClasses([
                curSection === 2 ? 'transition--in' : 'transition--out',
              ])}
            >
              {renderInputField(inputFieldsArrayTwo)}
              <Checkbox 
                className={
                  ConcatClasses([
                    'dis--block',
                    // border ? 'bor--grey-b-1' : '',
                    'float--right',
                    'pad--b-10',
                    'pad--t-10',
                    'text--left',
                    'width--100-p',
                  ])
                }
                checked={false}
                color="black"
                key='agree'
                id='agree'
                name='agree'
                onChange={(e) => {}}
                formName='pledge'
                required
                text="I agree to the "
                terms={true}
              />
            </div>
          </div>

          <div
            className={ConcatClasses([
              curSection === 200 || curSection === 500 ? 'transition--out' : '',
              'mar--t-50',
            ])}
          >
            <FormNav
              color='black'
              curSection={curSection}
              nextButton={nextButton}
              paginationArray={formPages}
              setSection={setSection}
            />
          </div>
          {/* SUCCESS || ERROR */}
          <div
            className={ConcatClasses([
              'bor--black-r-1-lrg',
              'mw--lrg-500-p',
              'pad--lrg-r-50',
              curSection === 200 || curSection === 500 ? 'transition--in mh--lrg-330 vertical-align--center' : 'transition--out',
            ])}
          >
            <div
                className={ConcatClasses([
                  'text--left',
                ])}
              >
              
              {curSection === 200 ? (
                <div dangerouslySetInnerHTML={{__html: success.copy}}  />
              ) : ''}
              {curSection === 500 ? (
                <div dangerouslySetInnerHTML={{__html: error.copy}}  />
              ) : ''}
            </div>
          </div>
        </Form>
        <div
          className={ConcatClasses([
            'dis--lrg-inline-block',
            'trans--l-30p-t-18p',
            'text--left',
            'ver-align--t',
          ])}
        >
          <Image
              alt={hero.alt}
              className={ConcatClasses([
                'dis--inline-block',
                'mw--340-p',
                'vis--hidden',
                'vis--lrg-visible',
                'width--100-p',
              ])}
              src={hero.headingImageMd}
          />
        </div>
      </section>
      <div
          className={
              ConcatClasses([
                  'btm--lr',
                  'width--100-p',
              ])
          }
      >
          <Terms 
              className={
                  ConcatClasses([
                      'bg--yellow',
                      'bg--lrg-black',
                      'text--center',
                      'pad--b-10',
                      'pad--t-10',
                  ])
              }
          />
          <Footer
              className={
                  ConcatClasses([
                      'bg--white',
                      'bg--lrg-black',
                      'pad--b-20',
                      'pad--t-20',
                      'text--center',
                      'text--lrg-left',
                  ])
              }
          />
      </div>
    </div>
  );
}

export default Pledge;
