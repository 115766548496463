import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import './_text.scss';

const Text = ({
  className = '',
  children = '',
  id = null,
  type = 'p',
  onClick = () => {},
}) => {
  const CustomeTag = type;
  return (
    <CustomeTag
      className={
        ConcatClasses([
          className,
          'pos--rel'
        ])
      }
      {...(id ? { id } : null)}
      onClick={onClick}
    >
      { children }
    </CustomeTag>
  );
}
export default Text;