import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';


import './_pagination.scss';

const Pagination = ({
    className = '',
    paginationArray,
}) => {
    const renderDots = paginationArray.map((dot) => {
        const {
          section,
        } = dot;
        return <span key={section} className='pag' />
    })

    return (
        <div className={ConcatClasses([
            'pagination--container',
            className,
        ])}>
            {renderDots}
        </div>

    );
}

export default Pagination;