const PaginationClasses = (direction, len, cur) => {
    let section = 0;
    if (cur >= len && direction === '+') {
        section = 1;
    } else if(direction === '+') {
        section = cur + 1;
    } else if(direction === '-') {
        section = cur - 1;
    }

    return section;
};

export default PaginationClasses;