import React from 'react';

import ConcatClasses from '../../utils/ConcatClasses';

import './_textarea.scss';

const TextArea = ({
    className = '',
    details = null,
    cols = '50',
    id = '',
    onChange = () => {},
    name = '',
    row = '4',
    placeholder = '',
}) => {

  return (
    <textarea
      className={ConcatClasses([
        className,
      ])}
      id={id}
      name={name}
      rows={row}
      cols={cols}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
    />
  );
}

export default TextArea;
