import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import './_button.scss';

const Button = ({
    children = '',
    className = '',
    arrow = false,
    onSubmit = () => {},
    onClick = () => {},
    type = '',
}) => {

    return (
        <button
            className={ConcatClasses([
                className,
                arrow ? 'button--arrow': '',
            ])}
            onClick={onClick}
            type={type}
        >
            {children}
            <span className="arrow"/>
        </button>
    );
}

export default Button;