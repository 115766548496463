const SiteContent = [
    {
        "home": {
            "hero": {
                "heading": "Opening Shot",
                "video": "https://vimeo.com/499776972/e416eff9d0",
                "videoBg": './static/images/medium/home_hero_medium.jpg',
                "headingImage" : './static/images/headers/home_header.svg',
                "copy" : "<span class='text--yellow text--bold'>Opening Shot</span> is a program created to promote diversity and inclusivity in the advertising industry by providing opportunities for individuals from diverse backgrounds to shadow professionals on an actual shoot.",
                "link" : "Apply"
            },
            "sectionOne": {
                "number": '1',
                "heading": "Find Your Fit",
                "copy" : "Explore the roles and discover your calling by learning about the advertising roles on set.",
                "art": './static/images/medium/find_your_fit_medium.png',
                "link": {
                    "href" : "/roles",
                    "text" : "Learn More"
                }
            },
            "sectionTwo": {
                "number": '2',
                "heading": "Apply",
                "copy" : "Apply by answering a few questions and telling us why you want to experience a shoot firsthand.",
                "art": './static/images/medium/apply_hero_medium.png',
                "link": {
                    "href" : "/apply",
                    "text" : "Apply"
                }
            },
            "sectionThree": {
                "number": '3',
                "heading": "Go On Set",
                "copy" : "Once you’ve been accepted, travel (or zoom) to a commercial shoot, to shadow a working professional on set.",
                "art": './static/images/medium/go_on_set_hero_medium.png',
            },
            "sectionFour": {
                "heading": "Get With The Program",
                "copy" : "If you are an agency or brand that would be interested in learning more about pledging productions, contact us here.",
                "links": [
                    {
                        "link": {
                            "href" : "mailto:hi@wipbdr.com",
                            "text" : "Contact Us"
                        },
                    }
                ],
                "logos": [
                    {
                        "alt" : "Colorado And CO",
                        "img" : "/"
                    },
                    {
                        "alt" : "Colorado And CO",
                        "img" : "/"
                    },
                    {
                        "alt" : "Colorado And CO",
                        "img" : "/"
                    },
                    {
                        "alt" : "Colorado And CO",
                        "img" : "/"
                    },
                    {
                        "alt" : "Colorado And CO",
                        "img" : "/"
                    }
                ]
            },
        },
        "roles" : {
            "hero" : {
                "heroImage":  './static/images/medium/roles_hero_large.jpg',
                "heading": 'Find Your Fit',
                "headingImage" : './static/images/headers/find_your_fit_header.svg',
                "graphic": "./static/images/graphic/graphic_roles.svg",
            },
            "sectionOne": {
                "number": '1',
                "heading": "ART DIRECTOR",
                "copy" : "A visionary of all things visual, you can tell a really good story just through a few images. You daydream in color, and redcorate, rearrange, or reimagine everyone and everything around you. You don't necessarily need to be able to draw, as much as you need to have a passion for design and killer taste.",
                "art": './static/images/medium/ad_hero_medium.png',
                "link": {
                    "href" : "/apply",
                    "text" : "Apply"
                }
            },
            "sectionThree": {
                "number": '2',
                "heading": "PRODUCER",
                "copy" : "You make things happen. You are the most organized person you know, with multiple spreadsheets on your desktop at this very moment. You can wheel and deal on the phone. Multitasking is your default mode, and making something amazing with limited time and resources is your comfort zone.",
                "art": './static/images/medium/producer_hero_medium.png',
                "link": {
                    "href" : "/apply",
                    "text" : "Apply"
                }
            },
            "sectionFour": {
                "number": '3',
                "heading": "COPYWRITER",
                "copy" : "You write stories, jokes, poems, plays, Christmas cards, and have a folder dedicated to all the ideas you want to write one day. You spend hours on perfecting each sentence you craft, even if that sentence is just a text to your mom. And everytime an ad comes on your television you think to yourself, “I could write one of these things.” ",
                "art": './static/images/medium/copy_writer_hero_medium.png',
                "link": {
                    "href" : "/apply",
                    "text" : "Apply"
                }
            },
            "sectionFive": {
                "number": '4',
                "heading": "ACCOUNT MANAGER",
                "copy" : "You’re like a Swiss army knife. No task is too small or big for you to take on. And no problem is too hard for you to solve. You use all of your tools to build momentum for the projects you manage and you’re focused on bringing the creative idea to life and making sure client feedback is always heard and addressed along the way. You know your client’s business as well as they do and are obsessed in partnering with them to achieve their goals.",
                "art": './static/images/medium/account_hero_medium.png',
                "link": {
                    "href" : "/apply",
                    "text" : "Apply"
                }
            },
        },
        "apply" : {
            "pageHeading": "Thank you.",
            "hero" : {
                "alt": "Apply Applicants Apply",
                "headingImage" : "./static/images/medium/thumbs_up_medium.png"
            },
            'success': {
                'copy': '<span class="text--yellow">Thank you</span>  your application has been submitted. We’ll email you with an acceptance or rejection letter once we have reviewed your application. Feel free to share this with others! '
            },
            'error': {
                'copy': 'Sorry, there was a problem submitting your application. Please try again.'
            },
            "questions": [
                {
                    "question": "Tell us about a time you faced adversity and what you did to overcome it.",
                    "details": "We recognize that diversity comes in many forms, including individuals of different racial or ethnic backgrounds, individuals with disabilities, individuals who are economically disadvantaged, individuals who identify as LGBT or gender-non-conforming, or other individuals who identify as having a trait, characteristic, or background that we want to embrace in our industry. Tell us what diversity means to you and how you could contribute your perspectives to the advertising industry."
                },
                {
                    "question": "WHAT INTERESTS YOU MOST ABOUT ADVERTISING?"
                }
            ] 
        },
        "partners": {
            "hero" : {
                "headingCopy" : "Make A Different",
                "copy": " If you are an agency or brand and want to join the amazing companies listed here, pledge your productions by filling out a few simple questions. "
            },
            "sectionOne" : {
                "headingCopy" : "PLEDGE A PRODUCTION",
            },
            "companies":[
                {
                    "logo": {
                        "alt" : "",
                        "url" : ''
                    }
                }
            ]
        },
        "pledge": {
            'pageHeading': 'Thank you.',
            "hero": {
                "alt" : "Pledges Apply",
                "headingImageSml" : "./static/images/headers/pledge_header_med.png",
                "headingImageMd" : './static/images/headers/pledge_header_med.png'
            },
            "callToAction": {
                "alt": "Get with the program",
                "url" : './static/images/headers/get_with_prog.svg'
            },
            "uploadCopy": "Please upload your logo as a png, transparent background, at least 72 dpi. Thank you!",
            "companies":[
                {
                    "logo": {
                        "alt" : "",
                        "url" : ''
                    }
                }
            ],
            'success': {
                'copy': '<span class="">Thank you</span>  your application has been submitted. We’ll email you with an acceptance or rejection letter once we have reviewed your application. Feel free to share this with others! '
            },
            'error': {
                'copy': 'Sorry, there was a problem submitting your application. Please try again.'
            }
        },
        "about": {
            "hero": {
                "heroImage":  './static/images/medium/about_hero.png',
                "heading": 'The Why',
                "headingImage" : './static/images/headers/about_header.svg'
            },
            "sectionOne": {
                "copy": "Opening Shot is a program dedicated to making the advertising industry more diverse by giving more people from different walks of life an opportunity to learn about advertising, experience a shoot firsthand, and form connections with people in the industry. ",
                "links": [
                    {
                        "link": {
                            "cta": "Apply",
                            "url": "/apply"
                        }
                    },
                    {
                        "link": {
                            "cta": "PLEDGE A PRODUCTION",
                            "url": "/pledge"
                        }
                    }
                ]
            }
        },
        "faq": {
            "hero": {
                "heading": "Facts & Questions",
                "heroImage":  './static/images/medium/faq_hero.jpg',
                "headingImage" : './static/images/headers/faq.svg'
            },
            "questions": [
                {
                    "title": "How are people being selected?",
                    "question": "A panel of unbiased advertising executives will make final decisions based on your entry submissions about adversity and advertising."
                },
                {
                    "title": "Do I need to pay for this?",
                    "question": "Nope! You will not be expected to pay a single dollar – the agency/brand that you will be partnered with will cover all fees!"
                },
                {
                    "title": "Do I need any advertising/production experience?",
                    "question": "Not at all! This is about learning and getting a shot!"
                },
                {
                    "title": "Do I need to bring anything onto the shoot?",
                    "question": "Just an open mind and enthusiasm! In truth, this will be determined by which production you will attend and those details will be established closer to the shoot date!"
                },
                {
                    "title": "Do I need anything for a virtual shoot?",
                    "question": "Just a computer and an internet connection, so you can connect to the production's virtual meeting and stream. If you don’t have a computer, we’ll help you find a library (or other facility) with computer and internet access."
                },
                {
                    "title": "How long do productions usually last?",
                    "question": "You should expect anywhere from 1-3 days of production that are 10 to 14 hours long, but again, these details will be determined and established closer to the shoot date!"
                },
                {
                    "title": "How do I know if I’ve been selected?",
                    "question": "You will be notified via the email you provide on your application."
                },
                {
                    "title": "Who books my travel?",
                    "question": "The agency that you will be partnered with will take care of your travel and accommodations."
                },
                {
                    "title": "What if I don’t feel comfortable flying?",
                    "question": "Travel may not be required for all productions, depending on your location and the location of the production. If flying is not an option for you, please disclose that in your application so we can try and find local productions that won’t require a flight or be remote."
                },
                {
                    "title": "How many times can I apply?",
                    "question": "If you’ve been rejected from the program you can reapply after 4 months."
                },
                {
                    "title": "How many productions can I attend?",
                    "question": "In an effort to provide the production experience for as many people as possible, we are limiting just one production per person."
                },
                {
                    "title": "What happens if the production is canceled?",
                    "question": "We will reassign a new production to you in the event of any canceled productions."
                }
            ]
        },
        "termsAndConditions" : {
            "terms": {
                "copyOne": '<h1 style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">EFFECTIVE DATE: 4.11.2022</h1>'+
                '    <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">Welcome!'+
                '        Opening Shot is a program created by WorkInProgress, LLC ("<b>WIP</b>" or "<b>we</b>," "<b>us</b>" or'+
                '        "<b>our</b>") to promote diversity and inclusivity in the advertising industry by providing opportunities for'+
                '        individuals from diverse backgrounds to shadow professionals on actual shoots (our "<b>Program</b>"). By'+
                '        participating in our Program or accessing or using the websites associated with our Program, including, but not'+
                '        limited to, <a href="http://www.openingshot.org/" class="a" target="_blank">https://</a><span'+
                '            class="s1">www.openingshot.org</span> (together with the features, functionalities, browser extensions and'+
                '        other services available through those websites, our "<b>Websites</b>") (our <b>Program </b>and <b>Websites'+
                '        </b>are collectively referred to in these Terms as our "<b>Offerings</b>"), you agree to read, comply with and'+
                '        be legally bound by: (1) these Terms of Use ("<b>Terms</b>"); (2) any additional terms and conditions,'+
                '        agreements and policies published on our Websites or otherwise made available to you that are applicable to your'+
                '        access to, use of or participation in our Offerings (collectively, the "<b>Rules</b>"); and (3) any other'+
                '        agreements applicable to your access to, use of or participation in our Offerings as we may enter into with you,'+
                '        or a third party on whose behalf you access, participate in or use our Offerings (collectively, the'+
                '        "<b>Additional Agreements</b>"). These Terms, the Rules and any and all Additional Agreements are collectively'+
                '        referred to in these Terms as the "<b>Agreements</b>".</p>'+
                '    <h1 style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">PLEASE NOTE,'+
                '        THESE TERMS DO NOT APPLY TO YOUR ACCESS TO OR USE OF ANY OTHER WEBSITES WIP OPERATES THAT ARE NOT ASSOCIATED OUR'+
                '        PROGRAM, INCLUDING WITHOUT LIMITATION, <a href="http://WWW.WIPBDR.COM/" class="s4"'+
                '            target="_blank">HTTPS://WWW.WIPBDR.COM</a>, AND THEY DO NOT APPLY TO THE SERVICES WE PROVIDE TO OUR CLIENTS'+
                '        THAT ARE NOT ASSOCIATED WITH OUR PROGRAM. THOSE OTHER WEBSITES AND SERVICES ARE SUBJECT TO SEPARATE AGREEMENTS,'+
                '        WHICH MAY INCLUDE, WITHOUT LIMITATION, THE TERMS OF USE AVAILABLE AT <a href="http://WWW.WIPBDR.COM/TERMS"'+
                '            class="s4" target="_blank">HTTPS://WWW.WIPBDR.COM/TERMS</a><a href="http://WWW.WIPBDR.COM/TERMS" class="s5"'+
                '            target="_blank">.</a></h1>'+
                '    <h1 style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">REVIEW THESE'+
                '        TERMS CAREFULLY. BY ACCESSING, PARTICIPATING IN OR USING OUR OFFERINGS (IN WHOLE OR IN PART), YOU AGREE TO BE'+
                '        BOUND BY THESE TERMS. IF YOU DO NOT AGREE WITH THESE TERMS YOU ARE NOT ELIGIBLE TO ACCESS, PARTICIPATE IN OR USE'+
                '        OUR OFFERINGS (IN WHOLE OR IN PART). FURTHER IF YOU ARE NOT ELIGIBLE TO ACCESS, PARTICIPATE IN OR USE OUR'+
                '        OFFERINGS PURSUANT TO ANY OF THE AGREEMENTS, YOU ARE NOT PERMITTED TO ACCESS, PARTICIPATE IN OR USE OUR'+
                '        OFFERINGS AS PROHIBITED UNDER SUCH AGREEMENTS.</h1>'+
                '    <h1 style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;">THESE TERMS'+
                '        REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN COURT OR JURY TRIALS OR'+
                '        CLASS ACTIONS AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE. CAREFULLY REVIEW THE'+
                '        AGREEMENT TO ARBITRATE PROVIDED IN SECTION 10 OF THESE TERMS BEFORE YOU ACCESS, PARTICIPATE IN OR USE OUR'+
                '        OFFERINGS.</h1>'+
                '    <ol id="l1">'+
                '        <li data-list-text="1.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">ACCEPTANCE AND'+
                '                APPLICABILITY OF AGREEMENTS</h1>'+
                '            <ol id="l2">'+
                '                <li data-list-text="1.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Acceptance of Agreements. <span class="p">These Terms are a legally binding agreement between'+
                '                            you and WIP. By accessing, participating in or using any of our Offerings, you expressly'+
                '                            acknowledge and agree that you understand the terms set forth in the applicable Agreement,'+
                '                            including, without limitation, those set forth in these Terms and that you agree to be'+
                '                            legally bound by all terms and conditions set forth in the applicable Agreements.</span>'+
                '                    </h1>'+
                '                </li>'+
                '                <li data-list-text="1.2">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Eligibility. <span class="p">Without limiting anything set forth in the Agreements, our'+
                '                            Offerings are intended for use by individuals at least 18 years old.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="1.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Your Representations and Warranties. <span class="p">Without limiting anything set forth in the'+
                '                            Agreements, by accessing, participating in or using our Offerings, you represent to us that:'+
                '                            (1) you are 18 years of age or older; (2) you are legally able to enter into contracts; (3)'+
                '                            you are not a person barred from accessing, participating in or using our Offerings under'+
                '                            federal, state, local or other laws; and (4) you have not previously been suspended or'+
                '                            prohibited from accessing, participating in or using our Offerings for any reason other than'+
                '                            your cancellation of your access, participation in or use of our Offerings in'+
                '                            accordance</span></h1>'+
                '                    <p style="padding-top: 4pt;padding-left: 51pt;text-indent: 0pt;line-height: 114%;text-align: left;">'+
                '                        with the applicable Agreements. Additionally, if you are accepting these terms on behalf of an'+
                '                        entity, you represent and warrant to us that you have the authority to bind that entity.</p>'+
                '                </li>'+
                '                <li data-list-text="1.4">'+
                '                    <p'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        <b>Changes to Terms. </b>We may update or modify these Terms from time to time, with or without'+
                '                        providing notice directly to you, by posting a revised version of these Terms on our Websites'+
                '                        and publishing a general notice of such changes on our Websites. You can review the most current'+
                '                        version of these Terms at any time by clicking the <span class="s6">Terms and Conditions</span>'+
                '                        link on our Websites. By accessing, participating in or using our Offerings after any'+
                '                        modification of these Terms, you agree to be bound by such modifications.</p>'+
                '                </li>'+
                '                <li data-list-text="1.5">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Our Rights. <span class="p">We have the right, but not the obligation, to take any of the'+
                '                            following actions in our sole discretion without providing any prior notice to you and'+
                '                            without liability to you or any third party:</span></h1>'+
                '                    <ol id="l3">'+
                '                        <li data-list-text="(a)">'+
                '                            <p style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;text-align: left;">change'+
                '                                or terminate all or any part of our Offerings;</p>'+
                '                        </li>'+
                '                        <li data-list-text="(b)">'+
                '                            <p style="padding-top: 9pt;padding-left: 31pt;text-indent: -18pt;text-align: left;">restrict'+
                '                                or terminate your access to, or ability to participate in or use all or any part of our'+
                '                                Offerings;</p>'+
                '                        </li>'+
                '                        <li data-list-text="(c)">'+
                '                            <p style="padding-top: 9pt;padding-left: 31pt;text-indent: -18pt;text-align: left;">refuse,'+
                '                                move or remove anything that is available on or through our Offerings; or</p>'+
                '                        </li>'+
                '                        <li data-list-text="(d)">'+
                '                            <p'+
                '                                style="padding-top: 9pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                deny access to or the ability to participate in or use our Offerings to anyone at any'+
                '                                time in our sole and absolute discretion.</p>'+
                '                        </li>'+
                '                    </ol>'+
                '                </li>'+
                '                <li data-list-text="1.6">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Conflicts. <span class="p">In the event of any conflict between these Terms and any other'+
                '                            Agreement, unless otherwise specified in such other Agreement, these Terms shall'+
                '                            control.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="2.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">ACCESS TO,'+
                '                PARTICIPATION IN AND USE OF OUR OFFERINGS</h1>'+
                '            <ol id="l4">'+
                '                <li data-list-text="2.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Acceptable Use. <span class="p">In addition to, and not in lieu of, any restrictions or'+
                '                            requirements set forth in any of the Agreements, your access to, participation in and use of'+
                '                            our Offerings must comply with the following (the "</span>Acceptable Use Restrictions<span'+
                '                            class="p">"):</span></h1>'+
                '                    <ol id="l5">'+
                '                        <li data-list-text="(a)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                You are only allowed to access and use our Offerings for their intended purposes, as'+
                '                                determined by us in our sole discretion.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(b)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                Without limitation, you are not allowed to access, participate in or use our Offerings'+
                '                                to: (1) publish, post, distribute or disseminate any content which is or could'+
                '                                reasonably be viewed as: (a) hate speech, obscene, harassing, threatening, pornographic,'+
                '                                abusive or defamatory to an individual or group of individuals on the basis of religious'+
                '                                belief, race, gender, age, disability or otherwise; (b) inciting violence, or containing'+
                '                                nudity or graphic or gratuitous violence; (c) an unauthorized commercial communication'+
                '                                of any kind (including, without limitation, spam); (d) fraudulent, inaccurate or'+
                '                                misleading, or otherwise objectionable content of any kind; (e) infringing or violating'+
                '                                someone else"™s rights or otherwise violating the law; or (f) identifying any person'+
                '                                without their consent or disclosing anyone else"™s personal contact details or invading'+
                '                                their privacy; (2) violate any laws; (3) transmit or upload any software or other'+
                '                                materials that contain any viruses, worms, trojan horses, defects, time bombs or other'+
                '                                items of a destructive nature; or (4) engage in commercial activity except as expressly'+
                '                                permitted under the applicable Agreements.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(c)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                You are also prohibited from: (1) reformatting or framing any portion of our Offerings;'+
                '                                (2) using any device, software, or procedure that interferes with, or attempts to'+
                '                                interfere with, the normal operation of our Offerings; (3) taking any action that'+
                '                                imposes, or may impose, as determined by us in our sole discretion, an unreasonable or'+
                '                                disproportionately large load on our information technology infrastructure; (4)'+
                '                                modifying, adapting, translating, disassembling, decompiling, translating or reverse'+
                '                                engineering any portion of our Offerings or otherwise attempting to reconstruct or'+
                '                                discover any source code or underlying ideas, algorithms, file formats, or</p>'+
                '                            <p'+
                '                                style="padding-top: 4pt;padding-left: 31pt;text-indent: 0pt;line-height: 114%;text-align: justify;">'+
                '                                programming interoperability interfaces of our Offerings; (5) disrupting or otherwise'+
                '                                interfering with our Offerings or the networks or servers we use; (6) impersonating any'+
                '                                person or entity or misrepresenting your connection or affiliation with a person or'+
                '                                entity; (7) collecting or storing, or attempting to collect or store, personal'+
                '                                information about other users of our Offerings, except as expressly permitted under the'+
                '                                applicable Agreements; (8) engaging in any activity that is illegal under federal,'+
                '                                state, local, or other laws; (9) creating a false identity in connection with your'+
                '                                access to, participation in or use of our Offerings; (10) releasing to any third party'+
                '                                information related to your access to, participation in or use of our Offerings for'+
                '                                purposes of monitoring our Offerings"™ availability, performance, or functionality, or'+
                '                                for any other benchmarking or competitive purposes without our prior written approval;'+
                '                                (11) copying our Offerings, except as expressly permitted under the applicable'+
                '                                Agreements; (12) accessing or using our Offerings in a service bureau or time-sharing'+
                '                                environment (including, without limitation, accessing our Offerings to provide third'+
                '                                parties a service consisting solely of the collection and entry of data and other'+
                '                                information on, or available through, our Offerings); (13) selling, assigning,'+
                '                                transferring, sublicensing, pledging, renting, or otherwise sharing your rights under'+
                '                                the Agreements; (14) creating any derivative works based on our Offerings; and (15)'+
                '                                modifying, obscuring, or removing any proprietary notices on our Offerings or copies'+
                '                                thereof.</p>'+
                '                        </li>'+
                '                    </ol>'+
                '                </li>'+
                '                <li data-list-text="2.2">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Access to our Offerings. <span class="p">Access to, participation in and use of some of our'+
                '                            Offerings require access to the Internet. You are responsible for providing all equipment'+
                '                            necessary to establish a connection to the Internet and otherwise necessary to access,'+
                '                            participate in and use the applicable Offerings, along with any telephone, wireless or other'+
                '                            connection and services fees associated with such connection. Accessing, participating in or'+
                '                            using our Offerings may allow you to receive content on your mobile phone or wireless'+
                '                            device. The manner in which that content is delivered to your mobile phone or wireless'+
                '                            device may cause you to incur extra data, text messaging or other charges from your wireless'+
                '                            carrier, which are your sole responsibility.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="2.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Compliance with Applicable Laws. <span class="p">You certify that you will comply with all'+
                '                            applicable laws (e.g., federal, state, local and other laws) and the applicable Agreements'+
                '                            when accessing, participating in or using our Offerings. Without limiting the foregoing, by'+
                '                            accessing, participating in or using our Offerings, you represent and warrant that: (1) you'+
                '                            are not located in a country that is subject to a U.S. Government embargo or that has been'+
                '                            designated by the U.S. Government as a "terrorist supporting" country; and</span></h1>'+
                '                    <p style="padding-left: 51pt;text-indent: 0pt;line-height: 114%;text-align: justify;">(2) you are'+
                '                        not listed on any U.S. Government list or prohibited or restricted parties. If you access,'+
                '                        participate in or use our Offerings outside the United States, you are solely responsible for'+
                '                        ensuring that your access to, participation in and use of our Offerings in such country,'+
                '                        territory or jurisdiction does not violate any applicable laws. We reserve the right, but have'+
                '                        no obligation to, in our sole discretion, monitor where users who access, participate in or use'+
                '                        Offerings are located, and the right, but not the obligation, to block or otherwise restrict'+
                '                        access to, participation in or use of our Offerings, in whole or in part, from any geographic'+
                '                        location.</p>'+
                '                </li>'+
                '                <li data-list-text="2.4">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Mistakes or Errors. <span class="p">Without limiting anything set forth in the applicable'+
                '                            Agreements, you acknowledge and agree that under no circumstances will we or any of our'+
                '                            licensors or suppliers be responsible for any loss, damage or liability arising out of any'+
                '                            mistakes or other errors made by you as a result of your access to or use of our'+
                '                            Offerings.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="3.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">OUR PROGRAM</h1>'+
                '            <ol id="l6">'+
                '                <li data-list-text="3.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Types of Participants. <span class="p">Our Program generally consists of two types of'+
                '                            participants "“ individuals from diverse backgrounds that want to pursue opportunities in the'+
                '                            advertising industry ("</span>Participants<span class="p">") and brands and advertising'+
                '                            agencies the provide Participants with opportunities to experience a shoot firsthand'+
                '                            ("</span>Partner Agencies<span class="p">").</span></h1>'+
                '                </li>'+
                '                <li data-list-text="3.2">'+
                '                    <h1 style="padding-top: 4pt;padding-left: 51pt;text-indent: -25pt;text-align: left;">Additional'+
                '                        Terms for Participants<span class="p">.</span></h1>'+
                '                    <ol id="l7">'+
                '                        <li data-list-text="(a)">'+
                '                            <h1'+
                '                                style="padding-top: 9pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                Applying to Participate in Our Program. <span class="p">Individuals who are interested'+
                '                                    in becoming a Participant can apply by completing a short application process'+
                '                                    available on our Websites. Applying to participate in our Program as a Participant'+
                '                                    does not guarantee that you will be selected to participate in our Program as a'+
                '                                    Participant. This is not an application for employment and there is no expectation'+
                '                                    of consideration for employment or future employment opportunities created by'+
                '                                    applying for the Program. If you are selected, your participation in our Program as'+
                '                                    a Participant may be conditioned on your agreement and compliance with certain Rules'+
                '                                    and Additional Agreements that we provide to you.</span></h1>'+
                '                        </li>'+
                '                        <li data-list-text="(b)">'+
                '                            <h1'+
                '                                style="padding-top: 8pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                Applicant and Participant Relationship to the Program. <span class="p">When an'+
                '                                    individual ("</span>Applicant<span class="p">") applies through the Websites to'+
                '                                    become a Participant in the Program, the Websites provide a portal for the applicant'+
                '                                    to provide and upload information pertinent to the Applicant"™s application, which'+
                '                                    may be used in the Program"™s evaluation of the Applicant"™s application. The Program'+
                '                                    will use such information, in its discretion, to consider the Applicant"™s'+
                '                                    application and the potential opportunities for the Applicant to be matched with a'+
                '                                    Partner Agency for the purpose of becoming a Participant in the Program. If selected'+
                '                                    as a Participant, we, through the Program, may perform certain administrative'+
                '                                    functions to assist Participant engagement with a Partner Agency, including but not'+
                '                                    limited to: (1) gathering contact information from Participant; (2) gathering and'+
                '                                    evaluating Participant"™s preferences and logistical needs for the purpose of'+
                '                                    participation in the Program; (3) assisting with scheduling and travel'+
                '                                    considerations, if any, for the purpose of Participant"™s participation in the'+
                '                                    Program; and (4) facilitating communications between Participant and a matched'+
                '                                    Partner Agency. The terms and conditions of the Participant"™s involvement in the'+
                '                                    Program will be subject to these Terms, as well as any additional Rules and'+
                '                                    Additional Agreements provided by us to Participant as a condition of participation'+
                '                                    in the Program. Applicants and Participants acknowledge they are not at any time an'+
                '                                    employee of the Program or WIP and that applying to and participating in the Program'+
                '                                    does not create an expectation of future employment opportunities.</span></h1>'+
                '                        </li>'+
                '                        <li data-list-text="(c)">'+
                '                            <h1'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                Applicant Acknowledgment on Program Responsivities. <span class="p">Applicants and'+
                '                                    Participants acknowledge that the neither the Program nor WIP are responsible for:'+
                '                                    (1) ensuring the accuracy of any information provided by Applicants or Participants;'+
                '                                    (2) making a determination as to a Participant"™s ongoing ability to participate in'+
                '                                    the Program with a Partner Agency; (3) making a final determination as to a'+
                '                                    Participant"™s travel and logistical requirements necessary to participate in the'+
                '                                    Program; (4) arranging for any accommodations by and between a Partner Agency and a'+
                '                                    Participant regarding Participant"™s ability to participate in the Program; (5)'+
                '                                    ensuring the accuracy of information provided by Partner Agencies; (6) advising'+
                '                                    Participants on any tax or accounting considerations in relation to their'+
                '                                    participation in the Program; (7) other than as outlined in any separate Additional'+
                '                                    Agreements or Rules, providing any tools or resources necessary for Participant to'+
                '                                    participate in the Program; and (8) monitoring or auditing Partner Agencies"™'+
                '                                    compliance with any laws, regulations, or ordinances governing the Program.</span>'+
                '                            </h1>'+
                '                        </li>'+
                '                    </ol>'+
                '                </li>'+
                '                <li data-list-text="3.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Partner Agencies. <a href="mailto:admin@openingshot.com" class="s8" target="_blank">If you are a'+
                '                            brand or advertising agency that is interested in participating in our Program as a Partner'+
                '                            Agency, you can contact us </a><span class="s1">admin@openingshot.com</span><span'+
                '                            class="s2"> </span><span class="p">or pledge to provide Participants the opportunity to'+
                '                            participate in one or more of your upcoming productions on our Websites. Please note,'+
                '                            participating in our Program as a Partner Agency is subject to our approval and will be'+
                '                            conditioned on your agreement and compliance with certain Rules and Additional Agreements'+
                '                            between you and WIP.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="4.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">OWNERSHIP AND LICENSE'+
                '            </h1>'+
                '            <ol id="l8">'+
                '                <li data-list-text="4.1">'+
                '                    <h1'+
                '                        style="padding-top: 4pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Ownership. <span class="p">All right, title and interest in and to our Websites, including, but'+
                '                            not limited to, the software and code that comprise and operate our Websites, and all of the'+
                '                            text, photographs, illustrations, images, graphics, audio, video, URLs and other materials'+
                '                            provided through or contained in our Websites are owned by us or third parties who have'+
                '                            licensed their content to us. Our Offerings are protected under trademark, service mark,'+
                '                            trade dress, copyright, patent, trade secret and other intellectual property laws. In'+
                '                            addition, each Website, individually, is a collective work under U.S. and international'+
                '                            copyright laws and treaties, and we own the copyright in the selection, coordination,'+
                '                            arrangement and enhancement of each Website.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="4.2">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Marks. <span class="p">The WIP names and logos, all product and service names, all graphics, all'+
                '                            button icons and all trademarks, service marks and logos appearing on or used in connection'+
                '                            with our Offerings, unless otherwise noted, are trademarks (whether registered or not),'+
                '                            service marks and/or trade dress of WIP (collectively, "</span>Our Marks<span class="p">").'+
                '                            All other trademarks, products names, company names, logos, services marks and/or trade'+
                '                            dress (collectively, "</span>Other Marks<span class="p">") mentioned, displayed, cited or'+
                '                            otherwise indicated within our Offerings are the property of their respective owners. You'+
                '                            are not authorized to display or use Our Marks in any manner without our prior written'+
                '                            permission. You are not authorized to display or use any Other Marks that appear on or are'+
                '                            used in connection with our Offerings without the prior written permission of the applicable'+
                '                            third party.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="4.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Limited License. <span class="p">Subject to your acceptance of, and compliance with, the'+
                '                            applicable Agreements, we hereby grant you a limited, non-exclusive, non-transferable,'+
                '                            non-sublicensable, revocable right to access and use our Websites in a manner that is'+
                '                            consistent with the applicable Agreements and the intended purposes of our Websites. You'+
                '                            obtain no rights in our Websites except to access and use them in accordance with the'+
                '                            applicable Agreements. Without limiting the generality of the foregoing, you shall not'+
                '                            access or use our Websites in violation of the terms set forth in any of the applicable'+
                '                            Agreements, including, without limitation, the Acceptable Use Restrictions set forth in'+
                '                            these Terms (collectively, the "</span>Prohibited Activities<span class="p">"). You will be'+
                '                            solely liable for any damages, costs or expenses arising out of or in connection with your'+
                '                            commission of any Prohibited Activity. You shall notify us immediately upon becoming aware'+
                '                            of the commission by any person of a Prohibited Activity and shall provide us with'+
                '                            reasonable assistance upon our request with any investigations we may conduct in connection'+
                '                            with any such Prohibited Activity.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="5.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">YOUR INFORMATION</h1>'+
                '            <ol id="l9">'+
                '                <li data-list-text="5.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Personal Information. <span class="p">By accessing, participating in or using our Offerings, we'+
                '                            may collect, use, disclose, transfer and/or share your personal information with third'+
                '                            parties (which may include, as applicable, other participants in our Program) as described'+
                '                            in our Privacy Policy. Our Privacy Policy may be updated from time to time, so please review'+
                '                            it regularly. If you do not want us to collect and use your personal information in the ways'+
                '                            described in our Privacy Policy, please discontinue all access to, participation in and use'+
                '                            of our Offerings.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="5.2">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Abusive and Offensive Language. <span class="p">Abusive and offensive language will not be'+
                '                            tolerated through or in connection with our Offerings, social media sites, or with our'+
                '                            personnel. You are not entitled to make untrue, malicious, and/or damaging comments with'+
                '                            regard to our operations in any media or forum.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="5.3">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Feedback. <span class="p">By submitting ideas, suggestions, documents, proposals, products,'+
                '                            and/or technologies ("</span>Ideas<span class="p">" or "</span>Innovations<span class="p">")'+
                '                            to us in any way, you acknowledge and agree that: (1) your Ideas and/or Innovations do not'+
                '                            contain confidential or proprietary information; (2) we are not under any obligation of'+
                '                            confidentiality, express or implied, with respect to the Ideas and Innovations; (3) we shall'+
                '                            be entitled to disclose (or choose not to disclose) such Ideas and Innovations for any'+
                '                            purpose and in any way; (4) we may have something similar to the Ideas and Innovations'+
                '                            already under consideration or in development;</span></h1>'+
                '                    <p'+
                '                        style="padding-top: 4pt;padding-left: 51pt;text-indent: 0pt;line-height: 114%;text-align: justify;">'+
                '                        (5) your Ideas and Innovations which are not subject to a patent, automatically become our'+
                '                        property without any obligation to you; and (6) you are not entitled to any compensation or'+
                '                        reimbursement of any kind from us under any circumstances.</p>'+
                '                </li>'+
                '                <li data-list-text="5.4">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Links to Third Party Websites. <span class="p">Our Websites may include links to other websites'+
                '                            or services solely as a convenience to you. You acknowledge and agree that we are not'+
                '                            responsible for the availability of such external websites or services and that we do not'+
                '                            endorse and are not responsible or liable for any such linked websites or services or any'+
                '                            information, material, products or services contained on or accessible through other'+
                '                            websites or services. Furthermore, we make no express or implied warranties with regard to'+
                '                            the information, material, products or services that are contained on or accessible through'+
                '                            linked websites or services. Your access to and use of linked websites or services,'+
                '                            including, without limitation, any information, material, products and services on or'+
                '                            accessible through other websites or services is solely at your own risk.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="6.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">TERMINATION</h1>'+
                '            <ol id="l10">'+
                '                <li data-list-text="6.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Right to Terminate. <span class="p">Subject to the terms set forth in the applicable Agreements,'+
                '                            in addition to, and not in lieu of, any of our other rights set forth in these Terms, we'+
                '                            reserve the right, with or without notice and in our sole discretion, to terminate these'+
                '                            Terms, your account and/or your ability to access, participate in and use our Offerings (in'+
                '                            whole or in part) for any reason, including, without limitation, for lack of use or if we'+
                '                            believe that you have violated or acted inconsistently with the letter or spirit of these'+
                '                            Terms, or, in the case of any activity by you that may harm us or other users, including,'+
                '                            but not limited to, fraud, abuse of privileges or misuse of our Offerings. You agree that we'+
                '                            will not be liable to you or any third party for any such termination.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="6.2">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Effects of Termination. <span class="p">If we exercise our termination rights available under'+
                '                            the applicable Agreements, your license to access, participate in and use our Offerings'+
                '                            shall immediately terminate and you must discontinue all access to, participation in and use'+
                '                            of our Offerings affected by such termination.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="6.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Fraudulent Activity. <span class="p">If we suspect that you are engaging in any fraudulent,'+
                '                            abusive, or illegal activity, we may refer such matter to appropriate law enforcement'+
                '                            authorities.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="6.4">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Survival. <span class="p">The provisions of these Terms which by their nature are intended to'+
                '                            survive the termination or cancellation of these Terms shall continue as valid and'+
                '                            enforceable obligations notwithstanding any such termination or cancellation. Without'+
                '                            limiting the foregoing, the provisions of these Terms regarding indemnity and limitations of'+
                '                            liability shall survive the termination or cancellation of these Terms.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="7.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">YOUR LIABILITY</h1>'+
                '            <ol id="l11">'+
                '                <li data-list-text="7.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Agreement to Indemnify. <span class="p">YOU AGREE THAT YOU WILL ONLY ACCESS AND USE OUR'+
                '                            OFFERINGS IN ACCORDANCE WITH THE APPLICABLE AGREEMENTS. YOU WILL COMPENSATE US IN FULL FOR'+
                '                            ANY LOSSES OR COSTS (INCLUDING REASONABLE ATTORNEYS"™ FEES) WHICH WE (OR ANY OF OUR'+
                '                            SUBSIDIARIES OR AFFILIATED COMPANIES) INCUR ARISING FROM ANY BREACH BY YOU OF THESE TERMS.'+
                '                            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD'+
                '                            US, OUR SUBSIDIARIES AND AFFILIATES AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS,'+
                '                            EMPLOYEES, AGENTS, AND OTHER REPRESENTATIVES (THE "</span>RELEASED PARTIES<span class="p">")'+
                '                            HARMLESS FROM AND AGAINST ANY CLAIM OR DEMAND, INCLUDING REASONABLE ATTORNEYS"™ FEES, MADE BY'+
                '                            ANY THIRD PARTY DUE TO OR ARISING OUT OF YOUR USE OF OUR OFFERINGS, YOUR CONNECTION TO OUR'+
                '                            OFFERINGS, YOUR VIOLATION OF THE APPLICABLE AGREEMENTS, OR YOUR VIOLATION OF ANY'+
                '                            INTELLECTUAL PROPERTY RIGHTS OF ANY OTHER PERSON OR ENTITY.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="7.2">'+
                '                    <h1'+
                '                        style="padding-top: 4pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Additional Remedies. <span class="p">The Released Parties reserve the right to seek all remedies'+
                '                            available at law and in equity for your violation of the applicable Agreements, including'+
                '                            the right to block access from a particular Internet address to our Websites and report'+
                '                            misuses to law enforcement.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="8.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">DISCLAIMERS</h1>'+
                '            <ol id="l12">'+
                '                <li data-list-text="8.1">'+
                '                    <h1 style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;text-align: left;">Disclaimer of'+
                '                        all Warranties</h1>'+
                '                    <ol id="l13">'+
                '                        <li data-list-text="(a)">'+
                '                            <p'+
                '                                style="padding-top: 9pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                OUR OFFERINGS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND WE EXPRESSLY DISCLAIM'+
                '                                ALL WARRANTIES OF ANY KIND RELATING TO OUR OFFERINGS (INCLUDING, BUT NOT LIMITED TO, ANY'+
                '                                SOFTWARE COMPRISING ANY PORTION OF OUR OFFERINGS), WHETHER EXPRESS OR IMPLIED,'+
                '                                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS'+
                '                                FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, THAT OUR OFFERINGS WILL BE WITHOUT ERROR OR'+
                '                                INVULNERABLE TO VIRUSES, WORMS OR OTHER HARMFUL SOFTWARE OR HARDWARE, OR THAT THE'+
                '                                OFFERINGS, OR ANY RESULTS FROM YOUR ACCESS TO, PARTICIPATION OR USE THEREOF WILL MEET'+
                '                                YOUR OR ANY THIRD PARTY"™S REQUIREMENTS OR ACHIEVE ANY INTENDED RESULT.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(b)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                YOU HEREBY ACKNOWLEDGE THAT OUR OFFERINGS MAY NOT BE AVAILABLE DUE TO ANY NUMBER OF'+
                '                                FACTORS INCLUDING, BUT NOT LIMITED TO, PERIODIC SYSTEM MAINTENANCE, SCHEDULED OR'+
                '                                UNSCHEDULED, ACTS OF GOD, UNAUTHORIZED ACCESS, VIRUSES, DENIAL OF SERVICE OR OTHER'+
                '                                ATTACKS, TECHNICAL FAILURE OF OUR OFFERINGS AND/OR TELECOMMUNICATIONS INFRASTRUCTURE, OR'+
                '                                DISRUPTION, AND THEREFORE WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTY'+
                '                                REGARDING THE USE AND/OR AVAILABILITY, ACCESSIBILITY, SECURITY, OR PERFORMANCE OF OUR'+
                '                                OFFERINGS CAUSED BY SUCH FACTORS.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(c)">'+
                '                            <p'+
                '                                style="padding-top: 8pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION,'+
                '                                MISDELIVERY, OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA,'+
                '                                INCLUDING, WITHOUT LIMITATION, ANY INFORMATION YOU SUBMIT THROUGH OUR WEBSITES.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(d)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                WE ARE NOT RESPONSIBLE OR LIABLE FOR, NOR DO WE REPRESENT OR OTHERWISE WARRANT THE'+
                '                                PERFORMANCE OF ANY DEVICE YOU USE TO ACCESS, PARTICIPATE IN OR USE OUR OFFERINGS,'+
                '                                INCLUDING, WITHOUT LIMITATION, THE CONTINUING COMPATIBILITY OF ANY DEVICE WITH OUR'+
                '                                OFFERINGS.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(e)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY, SOME'+
                '                                OF THE ABOVE DISCLAIMERS OF WARRANTIES MAY NOT APPLY TO YOU.</p>'+
                '                        </li>'+
                '                    </ol>'+
                '                </li>'+
                '                <li data-list-text="8.2">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Use of Offerings at Your Sole Risk. <span class="p">YOUR ACCESS TO, PARTICIPATION IN AND USE OF'+
                '                            OUR OFFERINGS, DOWNLOAD OF ANY SOFTWARE RELATING TO OUR OFFERINGS AND USE OF ANY INFORMATION'+
                '                            WE MAY PROVIDE, OR RESULTS GENERATED, THROUGH OR IN CONNECTION WITH YOUR ACCESS TO,'+
                '                            PARTICIPATION IN OR USE OF OUR OFFERINGS IS AT YOUR SOLE OPTION, DISCRETION AND RISK. WE'+
                '                            SHALL NOT BE LIABLE FOR ANY MALFUNCTIONS OF OUR OFFERINGS OR ANY SOFTWARE COMPRISING ANY'+
                '                            PORTION OF OUR OFFERINGS, BUGS OR VIRUSES RESULTING IN LOST DATA OR ANY OTHER DAMAGE TO YOUR'+
                '                            SOFTWARE, COMPUTER EQUIPMENT, MOBILE PHONE OR MOBILE DEVICE OR ANY OTHER EQUIPMENT YOU USE'+
                '                            TO ACCESS, PARTICIPATE IN OR USE, OR IN CONNECTION WITH, OUR OFFERINGS. FURTHERMORE, WE'+
                '                            SHALL NOT BE LIABLE FOR ANY ATTEMPTS BY YOU TO ACCESS, PARTICIPATE IN OR USE OUR OFFERINGS'+
                '                            BY METHODS, MEANS OR WAYS NOT INTENDED BY US. WE ARE NOT REQUIRED TO PROVIDE REDUNDANT OR'+
                '                            BACKUP NETWORKS AND/OR SYSTEMS.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="8.3">'+
                '                    <h1'+
                '                        style="padding-top: 4pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        No Creation of Warranty. <span class="p">NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN'+
                '                            OBTAINED BY YOU FROM ANY OF THE RELEASED PARTIES THROUGH OR FROM OUR OFFERINGS SHALL CREATE'+
                '                            ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="8.4">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Third Party Statements. <span class="p">WE DO NOT ENDORSE AND ARE NOT RESPONSIBLE FOR THE'+
                '                            STATEMENTS, ADVICE OR OPINIONS MADE BY ANYONE OTHER THAN AUTHORIZED WIP SPOKESPERSONS. WE DO'+
                '                            NOT ENDORSE AND ARE NOT RESPONSIBLE FOR ANY STATEMENTS, ADVICE OR OPINIONS PROVIDED BY ANY'+
                '                            THIRD PARTIES, AND SUCH STATEMENTS, ADVICE OR OPINIONS DO NOT IN ANY WAY REFLECT THE'+
                '                            STATEMENTS, ADVICE OR OPINIONS OF WIP.</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="9.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">LIMITATION OF LIABILITY'+
                '            </h1>'+
                '            <ol id="l14">'+
                '                <li data-list-text="9.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Maximum Liability. <span class="p">EXCEPT AS OTHERWISE SET FORTH IN THE APPLICABLE AGREEMENTS'+
                '                            AND WITHOUT LIMITING ANYTHING SET FORTH IN THESE TERMS, TO THE FULLEST EXTENT PERMITTED BY'+
                '                            APPLICABLE LAW, OUR ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, WITH RESPECT TO YOUR ACCESS'+
                '                            TO AND USE OF OUR OFFERINGS SHALL BE THE AMOUNT OF $100.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="9.2">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        No Liability for Incidental Damages. <span class="p">IN NO EVENT WILL WE BE LIABLE FOR ANY'+
                '                            INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES ARISING FROM'+
                '                            YOUR ACCESS TO OR USE OF OUR OFFERINGS OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR'+
                '                            ACCESS TO OR USE OF OUR OFFERINGS.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="9.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Applicability of Limitations. <span class="p">THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH'+
                '                            DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND'+
                '                            REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR WE WERE ADVISED OF THE POSSIBILITY OF'+
                '                            SUCH DAMAGES. SOME STATES OR JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY, SO'+
                '                            SOME OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. IN SUCH STATES OR'+
                '                            JURISDICTIONS, OUR LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE'+
                '                            LAW.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="9.4">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Notice to California Residents. <span class="p">IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY'+
                '                            WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES "A'+
                '                            GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO'+
                '                            EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER'+
                '                            MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="10.">'+
                '            <h1 style="padding-top: 7pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">DISPUTE RESOLUTION</h1>'+
                '            <h1 style="padding-top: 9pt;padding-left: 23pt;text-indent: 0pt;text-align: left;">PLEASE READ THIS'+
                '                CAREFULLY. IT AFFECTS YOUR RIGHTS.</h1>'+
                '            <ol id="l15">'+
                '                <li data-list-text="10.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Waiver of Rights. <span class="p">YOU AGREE THAT BY ACCESSING, PARTICIPATING IN, OR USING OUR'+
                '                            OFFERINGS YOU ARE WAIVING THE RIGHT TO A COURT OR JURY TRIAL OR TO PARTICIPATE IN A CLASS'+
                '                            ACTION. YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST THE RELEASED PARTIES ONLY IN YOUR'+
                '                            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,'+
                '                            REPRESENTATIVE, OR COLLECTIVE PROCEEDING. ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL'+
                '                            BASIS. CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="10.2">'+
                '                    <h1 style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;text-align: left;">Agreement to'+
                '                        Arbitrate.</h1>'+
                '                    <ol id="l16">'+
                '                        <li data-list-text="(a)">'+
                '                            <p'+
                '                                style="padding-top: 9pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                EXCEPT AS OTHERWISE SET FORTH IN THE APPLICABLE AGREEMENTS, YOU AGREE THAT ANY AND ALL'+
                '                                CLAIMS AND DISPUTES ARISING FROM OR RELATING IN ANY WAY TO THE SUBJECT MATTER OF THE'+
                '                                APPLICABLE AGREEMENTS, YOUR ACCESS TO, PARTICIPATION IN OR USE OF OUR OFFERINGS,</p>'+
                '                            <p'+
                '                                style="padding-top: 4pt;padding-left: 31pt;text-indent: 0pt;line-height: 114%;text-align: left;">'+
                '                                OR YOUR DEALINGS WITH THE RELEASED PARTIES SHALL BE FINALLY SETTLED AND RESOLVED THROUGH'+
                '                                BINDING INDIVIDUAL ARBITRATION AS DESCRIBED IN THIS SECTION.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(b)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                THIS AGREEMENT TO ARBITRATE INVOLVES INTERSTATE COMMERCE, AND, THEREFORE, SHALL BE'+
                '                                GOVERNED BY THE FEDERAL ARBITRATION ACT, 9 U.S.C. 1-16 ("<b>FAA</b>"), AND NOT BY STATE'+
                '                                LAW.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(c)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                THIS AGREEMENT TO ARBITRATE IS INTENDED TO BE INTERPRETED BROADLY PURSUANT TO THE FAA.'+
                '                            </p>'+
                '                        </li>'+
                '                        <li data-list-text="(d)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                THE ARBITRATION WILL BE GOVERNED BY THE COMMERCIAL ARBITRATION RULES AND THE'+
                '                                SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES OF THE AMERICAN ARBITRATION'+
                '                                ASSOCIATION ("<b>AAA</b>"), AS MODIFIED BY THIS SECTION.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(e)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 114%;text-align: justify;">'+
                '                                THE ARBITRATION WILL BE CONDUCTED USING ONE ARBITRATOR WITH SUBSTANTIAL EXPERIENCE IN'+
                '                                RESOLVING COMMERCIAL CONTRACT DISPUTES, WHO SHALL BE SELECTED FROM THE APPROPRIATE LIST'+
                '                                OF ARBITRATORS IN ACCORDANCE WITH THE ARBITRATION RULES AND PROCEDURES OF ANY'+
                '                                ARBITRATION ORGANIZATION OR ARBITRATOR THAT YOU AND THE RELEASED PARTIES AGREE UPON IN'+
                '                                WRITING OR THAT IS APPOINTED PURSUANT TO SECTION 5 OF THE FAA.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(f)">'+
                '                            <p'+
                '                                style="padding-top: 7pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                FOR ANY CLAIM WHERE THE TOTAL AMOUNT OF THE AWARD SOUGHT IS $10,000 OR LESS, YOU MUST'+
                '                                ABIDE BY THE FOLLOWING RULES: (1) THE ARBITRATION SHALL BE CONDUCTED SOLELY BASED ON'+
                '                                TELEPHONE OR ONLINE APPEARANCES AND/OR WRITTEN SUBMISSIONS AND (2) THE ARBITRATION SHALL'+
                '                                NOT INVOLVE ANY PERSONAL APPEARANCE BY THE PARTIES OR WITNESSES UNLESS OTHERWISE'+
                '                                MUTUALLY AGREED BY THE PARTIES. IF THE CLAIM EXCEEDS $10,0000, THE RIGHT TO A HEARING'+
                '                                WILL BE DETERMINED BY THE AAA RULES, AND THE HEARING (IF ANY) MUST TAKE PLACE IN DENVER,'+
                '                                COLORADO.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(g)">'+
                '                            <p'+
                '                                style="padding-top: 8pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                THE ARBITRATOR IS BOUND BY THE TERMS OF THIS AGREEMENT TO ARBITRATE. YOU ACKNOWLEDGE AND'+
                '                                AGREE THAT, IN ANY ARBITRATION PROCEEDING, NO DEPOSITIONS WILL BE TAKEN, AND ALL OTHER'+
                '                                FORMS OF DISCOVERY OF FACTS WILL BE LIMITED TO THOSE THINGS THAT THE ARBITRATOR'+
                '                                DETERMINES, IN ITS SOLE DISCRETION, TO BE NECESSARY. FURTHER, IN ANY ARBITRATION'+
                '                                PROCEEDING: (1) THERE SHALL BE NO AWARD OF PUNITIVE, EXEMPLARY, INCIDENTAL, OR'+
                '                                CONSEQUENTIAL OR OTHER SPECIAL DAMAGES; (2) ALL DAMAGES, CLAIMS, AND AWARDS WILL BE'+
                '                                GOVERNED BY ALASKA LAW; (3) THE PARTIES WILL CONDUCT THE ARBITRATION CONFIDENTIALLY AND'+
                '                                EXPEDITIOUSLY AND WILL PAY THEIR OWN COSTS AND EXPENSES OF ARBITRATION, INCLUDING THEIR'+
                '                                OWN ATTORNEYS"™ FEES. IF YOU PROVE THAT YOU ARE UNABLE TO AFFORD THE AAA FEE, YOU AGREE'+
                '                                TO NOTIFY ALL PERSONS AGAINST WHOM YOU HAVE AN ARBITRABLE CLAIM AND GIVE SUCH PERSONS'+
                '                                THE OPPORTUNITY, INDIVIDUALLY AND AS A GROUP, TO PAY SUCH FEE. THE PROCEEDING AND THE'+
                '                                DECISION SHALL BE KEPT CONFIDENTIAL BY THE ARBITRATOR AND THE PARTIES.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(h)">'+
                '                            <p'+
                '                                style="padding-top: 8pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                THE ARBITRATOR MAY AWARD DECLARATORY OR INJUNCTIVE RELIEF ONLY IN FAVOR OF THE'+
                '                                INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF'+
                '                                WARRANTED BY THAT PARTY"™S INDIVIDUAL CLAIM.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(i)">'+
                '                            <p'+
                '                                style="padding-top: 8pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                THE ARBITRATOR"™S RULING IS BINDING AND MAY BE ENTERED AS A JUDGMENT IN ANY COURT OF'+
                '                                COMPETENT JURISDICTION, OR APPLICATION MAY BE MADE TO SUCH COURT FOR JUDICIAL ACCEPTANCE'+
                '                                OF ANY AWARD AND AN ORDER OF ENFORCEMENT, AS THE CASE MAY BE.</p>'+
                '                        </li>'+
                '                        <li data-list-text="(j)">'+
                '                            <p'+
                '                                style="padding-top: 8pt;padding-left: 31pt;text-indent: -18pt;line-height: 113%;text-align: justify;">'+
                '                                EXCEPT IN THE EVENT YOU OPT OUT OF THIS AGREEMENT TO ARBITRATE IN ACCORDANCE WITH'+
                '                                SECTION 10.5, THIS AGREEMENT TO ARBITRATE WILL SURVIVE TERMINATION OF YOUR ACCESS TO OR'+
                '                                USE OF OUR OFFERINGS AND YOUR RELATIONSHIP WITH THE RELEASED PARTIES.</p>'+
                '                        </li>'+
                '                    </ol>'+
                '                </li>'+
                '                <li data-list-text="10.3">'+
                '                    <h1'+
                '                        style="padding-top: 4pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Information About Arbitration. <a href="http://WWW.ADR.ORG/" class="s8"'+
                '                            target="_blank">INFORMATION ON AAA AND HOW TO START ARBITRATION CAN BE FOUND AT </a><a'+
                '                            href="http://WWW.ADR.ORG/" class="a" target="_blank">WWW.ADR.ORG</a><a'+
                '                            href="http://WWW.ADR.ORG/" class="s8" target="_blank">.</a><span class="p"> THERE IS NO'+
                '                            JUDGE OR JURY IN ARBITRATION. ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN RULES'+
                '                            APPLICABLE IN COURT AND REVIEW BY A COURT IS LIMITED. YOU WILL NOT BE ABLE TO HAVE A COURT'+
                '                            OR JURY TRIAL OR PARTICIPATE IN A CLASS ACTION OR CLASS ARBITRATION. YOU UNDERSTAND AND'+
                '                            AGREE THAT BY AGREEING TO RESOLVE ANY DISPUTE THROUGH INDIVIDUAL ARBITRATION, YOU ARE'+
                '                            WAIVING THE RIGHT TO A COURT OR JURY TRIAL. ANY DISPUTE SHALL BE ARBITRATED ON AN INDIVIDUAL'+
                '                            BASIS, AND NOT AS A CLASS ACTION, REPRESENTATIVE ACTION, CLASS ARBITRATION, OR SIMILAR'+
                '                            PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE THE CLAIMS OF MULTIPLE PARTIES.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="10.4">'+
                '                    <p'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        <b>Challenges to Validity of Agreement to Arbitrate. </b>YOU AGREE THAT ALL CHALLENGES TO THE'+
                '                        VALIDITY AND APPLICABILITY OF THIS AGREEMENT TO ARBITRATE (<i>i.e.</i>, WHETHER A PARTICULAR'+
                '                        CLAIM OR DISPUTE IS SUBJECT TO ARBITRATION) SHALL BE DETERMINED BY THE ARBITRATOR.'+
                '                        NOTWITHSTANDING ANY PROVISION IN THE APPLICABLE AGREEMENTS TO THE CONTRARY, IF THE CLASS ACTION'+
                '                        WAIVER ABOVE IS DEEMED INVALID OR UNENFORCEABLE, YOU AGREE THE YOU SHALL NOT SEEK TO, AND WAIVE'+
                '                        ANY RIGHT TO, ARBITRATE CLASS OR COLLECTIVE CLAIMS.</p>'+
                '                </li>'+
                '                <li data-list-text="10.5">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Opt-Out of Agreement to Arbitrate. <span class="p">IF YOU WISH TO OPT OUT OF THE AGREEMENT TO'+
                '                            ARBITRATE, WITHIN 45 DAYS OF 4.11.2022 OR THE DATE WHEN YOU FIRST ACCESS, PARTICIPATE IN OR'+
                '                            USE OUR OFFERINGS, WHICHEVER IS LATER, YOU MUST SEND A LETTER STATING: "REQUEST TO OPT-OUT'+
                '                            OF AGREEMENT TO ARBITRATE" TO US AT:</span></h1>'+
                '                    <p style="padding-top: 7pt;padding-left: 51pt;text-indent: 0pt;line-height: 114%;text-align: left;">'+
                '                        WorkInProgress, LLC 2465 Central Ave</p>'+
                '                    <p style="padding-left: 51pt;text-indent: 0pt;line-height: 11pt;text-align: left;">Suite 110</p>'+
                '                    <p style="padding-top: 1pt;padding-left: 51pt;text-indent: 0pt;text-align: left;">Boulder, CO 80301'+
                '                    </p>'+
                '                </li>'+
                '                <li data-list-text="10.6">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Venue for Litigation. <span class="p">IF THE AGREEMENT TO ARBITRATE IS FOUND UNENFORCEABLE OR TO'+
                '                            NOT APPLY FOR A GIVEN DISPUTE, IF YOU OPT OUT OF THE AGREEMENT TO ARBITRATE IN ACCORDANCE'+
                '                            WITH SECTION 10.5, OF IF OTHERWISE SET FORTH IN THE APPLICABLE AGREEMENTS, THEN THE'+
                '                            PROCEEDINGS FOR ANY CAUSE OF ACTION OR CLAIM UNDER THESE TERMS MUST BE BROUGHT EXCLUSIVELY'+
                '                            IN THE FEDERAL COURTS OF THE UNITED STATES ENCOMPASSING DENVER, COLORADO OR THE APPLICABLE'+
                '                            STATE COURTS OF THE STATE OF COLORADO ENCOMPASSING DENVER, COLORADO, AS APPROPRIATE, AND YOU'+
                '                            AGREE TO THE PERSONAL JURISDICTION OF EACH OF THESE COURTS FOR THE PURPOSE OF LITIGATING'+
                '                            SUCH CLAIMS OR DISPUTES.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="10.7">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Time to Bring a Claim. <span class="p">EXCEPT AS OTHERWISE PROVIDED IN THE APPLIABLE AGREEMENTS,'+
                '                            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE AGAINST THE RELEASED PARTIES ARISING OUT OF OR'+
                '                            RELATING IN ANY WAY TO THE APPLICABLE AGREEMENTS, YOUR ACCESS TO, PARTICIPATION IN OR USE OF'+
                '                            OUR OFFERINGS OR YOUR DEALINGS WITH THE RELEASED PARTIES IN CONNECTION WITH OUR OFFERINGS'+
                '                            MUST BE COMMENCED WITHIN TWO (2) YEARS AFTER THE CAUSE OF ACTION ACCRUES. AFTER THAT TWO (2)'+
                '                            YEAR PERIOD, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED. SOME JURISDICTIONS DO NOT'+
                '                            ALLOW TIME LIMITATIONS OTHER THAN THOSE SET FORTH IN SUCH STATE"™S STATUTE OF LIMITATIONS'+
                '                            LAWS. IN SUCH CASES, THE APPLICABLE STATUTE OF LIMITATIONS PROVIDED FOR UNDER THE LAWS OF'+
                '                            SUCH STATE SHALL APPLY.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="10.8">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Prevailing Party. <span class="p">IN ANY ARBITRATION PROCEEDING OR LITIGATION (AS APPLICABLE)'+
                '                            BETWEEN YOU AND THE RELEASED PARTIES IN CONNECTION WITH ANY OF THE APPLICABLE AGREEMENTS,'+
                '                            YOUR ACCESS TO, PARTICIPATION IN OR USE OF OUR OFFERINGS OR YOUR DEALINGS WITH THE RELEASED'+
                '                            PARTIES IN CONNECTION WITH OUR OFFERINGS, THE PREVAILING PARTY WILL BE ENTITLED TO RECOVER'+
                '                            ITS REASONABLE ATTORNEYS"™ FEES AND COSTS IN SUCH LITIGATION FROM THE OTHER PARTY.</span>'+
                '                    </h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '        <li data-list-text="11.">'+
                '            <h1 style="padding-top: 4pt;padding-left: 26pt;text-indent: -21pt;text-align: left;">ADDITIONAL TERMS</h1>'+
                '            <ol id="l17">'+
                '                <li data-list-text="11.1">'+
                '                    <h1'+
                '                        style="padding-top: 9pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Governing Law. <span class="p">These Terms will be governed by the laws of Colorado, without'+
                '                            giving effect to any principles of conflicts of laws.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="11.2">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Electronic Communications. <span class="p">The communications between you and WIP use electronic'+
                '                            means, whether you access or use our Offerings or send us emails, or whether we post notices'+
                '                            on our Offerings or communicate with you via email. For contractual purposes, you: (1)'+
                '                            consent to receive communications from us in an electronic form; and (2) agree that all'+
                '                            terms and conditions, agreements, notices, disclosures, and other communications that we'+
                '                            provide to you electronically, including, without limitation, the Rules, satisfy any legal'+
                '                            requirement that such communications would satisfy if it were be in a hardcopy writing. The'+
                '                            foregoing does not affect your non-waivable rights.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="11.3">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Severability. <span class="p">If any part of these Terms are determined to be invalid or'+
                '                            unenforceable pursuant to applicable law, including, but not limited to, the warranty'+
                '                            disclaimers and limitations of liability set forth above, then the invalid or unenforceable'+
                '                            provision will be deemed superseded by a valid, enforceable provision that most closely'+
                '                            matches the intent of the original provision and the remainder of the applicable Terms shall'+
                '                            continue in effect.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="11.4">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 114%;text-align: justify;">'+
                '                        Waivers. <span class="p">Our failure to enforce any provision of these Terms shall not be deemed'+
                '                            a waiver of such provision nor of the right to enforce such provision.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="11.5">'+
                '                    <h1'+
                '                        style="padding-top: 7pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Admissibility of Terms. <span class="p">A printed version of these Terms and of any notice given'+
                '                            in electronic form shall be admissible in judicial or administrative proceedings based upon'+
                '                            or relating to these Terms to the same extent and subject to the same conditions as other'+
                '                            business documents and records originally generated and maintained in printed form.</span>'+
                '                    </h1>'+
                '                </li>'+
                '                <li data-list-text="11.6">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 113%;text-align: justify;">'+
                '                        Assignment. <span class="p">We reserve the right to transfer, assign, sublicense or pledge these'+
                '                            Terms, in whole or in part, to any person without notice, provided that any such assignment'+
                '                            will be on the same terms or terms that are no less advantageous to you. You may not assign,'+
                '                            sublicense or otherwise transfer in any manner whatsoever any of your rights or obligations'+
                '                            under these Terms.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="11.7">'+
                '                    <h1'+
                '                        style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;line-height: 112%;text-align: justify;">'+
                '                        Headings. <span class="p">The section headings and sub-headings contained in these Terms are for'+
                '                            convenience only and have no legal or contractual effect.</span></h1>'+
                '                </li>'+
                '                <li data-list-text="11.8">'+
                '                    <h1 style="padding-top: 8pt;padding-left: 51pt;text-indent: -25pt;text-align: left;">Support. <span'+
                '                            class="p">If you have any questions or concerns about our Offerings or these Terms, please'+
                '                            email us at</span></h1>'+
                '                </li>'+
                '            </ol>'+
                '        </li>'+
                '    </ol>'+
                '    <p style="padding-top: 1pt;padding-left: 51pt;text-indent: 0pt;text-align: left;"><a'+
                '            href="mailto:admin@openingshot.org" class="s5" target="_blank">admin@openingshot.org</a><a'+
                '            href="mailto:admin@openingshot.org" class="s8" target="_blank">.</a></p>',
                "copyTwo": ""
            },
            "links": [
                {
                    "title" : "About Us",
                    "href" : "/about"
                },
                {
                    "title": "News",
                    "href" : "/"
                },
                {
                    "title": "Press Releases",
                    "href" : "/"
                },
                {
                    "title" : "Get Involved",
                    "href" :"/"
                },
                {
                    "title": "Contact Us",
                    "href" : "/"
                },
                {
                    "title" : "Terms and Conditions",
                    "href" : "/"
                }
            ]
        },
        "menu": {
            "social": [
                {
                    "name": "Instagram",
                    "link": "https://www.instagram.com/openingshot_/",
                    "imageUrl": "./static/images/icons/ig_icon_black.svg"
                },
            ]
        },
        "footer": {
            "copy": "WorkInProgress, LLC",
            "links": [
                {
                    "row": [
                        {
                            "color": "white",
                            "title": "Get involved",
                            "href": "/pledge",
                            "heading": "3",
                            "target": "_self"
                        },
                        {
                            "color": "grey",
                            "title": "APPLY TO GET YOUR OPENING SHOT",
                            "href": "/apply",
                            "heading": "4",
                            "target": "_self"
                        },
                    ]
                },
                {
                    "row": [
                        {
                            "download": false,
                            "color": "white",
                            "title": "CONTACT US",
                            "href": "mailto:admin@openingshot.org",
                            "heading": "4",
                            "target": "email"
                        },
                        {
                            "download": false,
                            "color": "white",
                            "title": "TERMS AND CONDITIONS",
                            "href": "/terms",
                            "heading": "4",
                            "target": "_blank"
                        },
                        {
                            "color": "white",
                            "title": "PRIVACY POLICY",
                            "download": true,
                            "href": "./static/images/WIP_OpeningShot_PrivacyPolicy_4.11.2022.pdf",
                            "heading": "4",
                            "target": "_self"
                        },
                    ]
                }
            ]
        }
    },
];

export default SiteContent;