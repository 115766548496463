import React, { Fragment, useState } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import ConcatClasses from '../../utils/ConcatClasses';

import Image from '../simple/Image';
import LinkComp from '../simple/Link';
import Text from '../simple/Text';
import Video from '../smart/Video';

import './_art.scss';

const Art = ({
    align = 'left',
    bg = 'light',
    className = '',
    children = '',
    copyBg='white',
    copy = '',
    heading = '',
    link = {
        href: '',
        text: '',
    },
    imgSrc = '',
    number,
    videoUrl = null,
}) => {
    // hooks
    const [openVideo, setVideo] = useState(null);
    const {
        href,
        text,
    } = link;
    const handleVideoClick = (e) => {
        e.preventDefault();
        setVideo(openVideo => !openVideo);
    }

    const textColor = copyBg === 'blue' ||  copyBg === 'black' ? 'white' : '';
    const renderArt = () => {
        return (
            <div
                className={
                    ConcatClasses([
                        align === 'center' ? 'text--right' : '',
                        'image--wrapper',
                        'pointer--none',
                ])}
            >
                <Image
                    className={
                        ConcatClasses([
                            align === 'center' ? 'dis--inline-block' : '',
                    ])}
                    alt={heading}
                    src={imgSrc}
                />
            </div>
        )
    }

    return (
    <div
      className={
        ConcatClasses([
          className,
        ])
      }
    >
        <div
            className={ConcatClasses([
                'art--wrapper',
                align !== 'center' ? '' : '',
                `text--box-${copyBg}`,
                'mar--0-auto',
                align === 'center' ? 'mar--t-30 pad--t-65-p pad--lrg-t-0' : '',
                'pad--t-50',
                align !== 'left' ? 'width--90-p' : '',
                align === 'left' ? 'mar--t-50 pad--lrg-t-505 pad--lrg-l-40-p width--95-p' : '',
                'width--lrg-70-p',
            ])}
        >
            {align === 'left' ? renderArt() : ''}
            {align === 'center' ? renderArt() : ''}
            {/* TEXT BOX */}
            <div
                className={ConcatClasses([
                    'text--wrapper',
                    `bg--${copyBg}`,
                    'dis--inline-block',
                    'mar--0-auto',
                    'pad--20',
                    align === 'center' ? 'pad--lrg-l-50 pad--t-90 width--90-p width--lrg-75-p' : '',
                    align === 'left' ? 'mar--l-30p mar--lrg-l-0 width--55-p' : '',
                    align === 'right' ? 'mar--lrg-l-80 width--90-p  width--lrg-75-p' : '',
                    'width--lrg-60-p',
                    'ver-align--t',
                ])}
            >
                <div
                    className={ConcatClasses([
                        openVideo ? 'transition--fade' : 'transition--in',
                    ])}
                >
                    <Text
                        className={ConcatClasses([
                            'heading--xl',
                            align === 'left' ? 'pad--l-5-p' : '',
                            'text--' + textColor,
                        ])}
                    >
                        {number}
                    </Text> 
                    <Text
                        className={ConcatClasses([
                            align === 'right' ? 'pad--r-50-p' : '',
                            align === 'left' ? 'pad--l-5-p' : '',
                            'text--' + textColor,
                            'mar--lrg-b-30',
                        ])}
                    >
                        <span
                            className={ConcatClasses([
                                'heading--1',
                                'heading--lrg-0',
                                'text--uppercase',
                            ])}
                            >{heading}</span>
                        <br />
                        <span
                            className={ConcatClasses([
                                'text--line-height-30',
                                'body--copy',
                            ])}
                        >
                            {copy}
                        </span>
                    </Text>
                </div>
                {openVideo ? (
                    <div
                        className={ConcatClasses([
                            'mh--250',
                            'mar--0-auto',
                            'transition--fade',
                            openVideo ? 'transition--in' : 'transition--out',
                        ])}
                    >
                        <Video
                            backgroundImage={null}
                            IsTouchClasses
                            className={
                                ConcatClasses([
                                    'pad--lrg-l-20-p',
                                    'width--100-p',
                                    'z--0',
                                ])
                            }
                            videoLink={videoUrl}
                        />
                    </div>

                ) : ''}
                {videoUrl ? (
                    <div
                        className={
                            ConcatClasses([
                                align === 'left' ? 'pad--l-20-p' : '',
                                'mar--b-20',
                        ])}
                    >
                        <div
                            className={
                                ConcatClasses([
                                    'pointer--cursor',
                                    'link',
                                    'link--border',
                                    bg === 'light' ? 'link--white' : 'link--black',
                                    'text--black',
                                    'text--uppercase',
                            ])}
                            onClick={(e) => handleVideoClick(e)}
                        >
                            {openVideo ? 'Back' : text}
                            <span className='arrow'/>
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            ConcatClasses([
                                align === 'left' ? 'pad--l-20-p' : '',
                                'mar--b-20',
                                'vis--hidden',
                                'vis--lrg-visible',
                        ])}
                    >
                        {href ? (
                            <LinkComp
                                arrow
                                className={
                                    ConcatClasses([
                                        bg === 'light' ? 'link--white' : 'link--black',
                                        'text--black',
                                        'text--uppercase',
                                        'text--underline',
                                        'vis--hidden',
                                        'vis--lrg-visible',
                                ])}
                                href={href}
                            >            
                                    {text}
                            </LinkComp>
                        ) : ''}
                    </div>
                )}
            </div>

            {align === 'right' ? renderArt() : ''}
            {videoUrl ? '' : (
                <div
                    className={
                        ConcatClasses([
                            'mar--b-45',
                            'mar--t-45',
                            'text--center',
                            'vis--lrg-hidden',
                            'z--1',
                    ])}
                >
                    {href ? (
                        <LinkComp
                            arrow
                            className={
                                ConcatClasses([
                                    bg === 'light' ? 'link--black' : 'link--white',
                                    'text--black',
                                    'text--uppercase',
                            ])}
                            href={href}
                        >            
                            {text}
                        </LinkComp>
                    ) : ''}
                </div>
            )}
         </div>
    </div>
  );
}
export default Art;