import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import ConcatClasses from '../../utils/ConcatClasses';

import './_video.scss';

const Video = ({
    className = '',
    videoLink = '',
    backgroundImage = './static/images/medium/home_hero_medium.jpg',
}) => {
    return (
        <Vimeo
            allowfullscreen
            autoplay
            className={
                ConcatClasses([
                    className,
                ])
            }
            loop
            muted
            style={{ 
                backgroundImage: "url(" + backgroundImage + ")",
            }}
            video={videoLink}
        />
    );
}

export default Video;