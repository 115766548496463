import React, { Fragment } from 'react';

import ConcatClasses from '../../utils/ConcatClasses';
import PaginationClasses from '../../utils/PaginationClasses';

import Button from '../simple/Button';
import Pagination from '../simple/Pagination';
import Text from '../simple/Text';

// import './exapmle.scss';

const FormNav = ({
    color = 'white',
    curSection = 1,
    nextButton = true,
    paginationArray = [],
    setSection = () => {},
}) => {
    const hanldePagination = (direction) => {
        const section = PaginationClasses(direction, paginationArray.length, curSection);
        setSection(section);
    }

    return (
        <Fragment>
        <div
            className='vertical-align--top'
        >
            <div>
                <Text
                    onClick={() => hanldePagination('-')}
                    className={
                    ConcatClasses([
                        'dis--inline-block',
                        'heading--3',
                        'link',
                        `link--${color}`,
                        'link--lrg-arrow',
                        'link--arrow-back',
                        'link--border',
                        'pointer--cursor',
                        'text--center',
                        `text--${color}`,
                        curSection === 1 ? 'vis--hidden' : '',
                        'ver-align--t',
                    ])
                    }
                >
                <span className="arrow" />
                Back
                </Text>
            </div>

            <div
              className={
                ConcatClasses([
                  'dis--inline-block',
                ])
              }
            >
                {paginationArray.length === curSection ? (
                    <Fragment>
                        <Button
                            arrow
                            className={
                            ConcatClasses([
                                `button--${color}`,
                                'link--border',
                                'link--lrg-arrow',
                            ])
                            }
                            type='submit'
                        >
                            <Text
                                className={
                                    ConcatClasses([
                                    'text--black',
                                    `text--${color}`,
                                    ])
                                }
                            >
                            Submit
                            </Text>
                        </Button>
                    </Fragment>
                ) : (
                    <Text
                        onClick={() => hanldePagination('+')}
                        className={
                            ConcatClasses([
                            'dis--inline-block',
                            'heading--3',
                            'link',
                            `link--${color}`,
                            'link--border',
                            'link--lrg-arrow',
                            'pointer--cursor',
                            'text--center',
                            nextButton ? '' : 'opacity--50 pointer--none',
                            'ver-align--t',
                            ])
                        }
                        >
                        NEXT
                        <span className="arrow" />
                        </Text>
                )}
                {/* {curSection <= 2 ? (

              ) : ''} */}
            </div>
        </div>
        <div
            className={ConcatClasses([
                'text--center',
            ])}
            >
            <Pagination 
                className={ConcatClasses([
                `pag--${color}`,
                `on-${curSection}`,
                ])}
                paginationArray={paginationArray}
            />
        </div>
        </Fragment>
    );
}

export default FormNav;