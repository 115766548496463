import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import './_select.scss';

const Select = ({
    defaultProps = 'string prop',
    className = '',
    name = '',
    required = false,
    onChange = () => {},
    onBlur = () => {},
}) => {
    return (
        <select
            className={ConcatClasses([
                'select',
                className,
            ])}
            required={required}
            id={name}
            name={name} 
            onBlur={onBlur}
            onChange={onChange}
        >
            <option value="">Brand or Agency</option>
            <option value="Agency">Agency</option>
            <option value="Brand">Brand</option>
        </select>
    );
}

export default Select;