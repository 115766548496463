import React from "react";
import { Switch, Route, HashRouter, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import SiteContent from '../../json/SiteContent';

import About from '../../components/pages/About';
import Apply from '../../components/pages/Apply';
import Faqs from '../../components/pages/Faqs';
import Home from '../../components/pages/Home';
import Pledge from '../../components/pages/Pledge';
import Partners from '../../components/pages/Partners';
import Roles from '../../components/pages/Roles';
import Terms from '../../components/pages/Terms';
import FourZeroFour from '../../components/pages/FourZeroFour';

import './_transitions.scss';
import '../simple/_link.scss';

function AppRouter({ location }) {
    const siteContent = SiteContent[0];
    console.log('APP RUTER ')

    return (
        <TransitionGroup className="transition-group height--lrg-100-p width--lrg-100-p">
            <CSSTransition
                key={location.key}
                timeout={{ enter: 800, exit: 800 }}
                className="page--transtion"
            >
                <Switch location={location}>
                    <Route
                        component={() =>
                        <Home
                            content={siteContent.home}
                            pledge={siteContent.pledge}
                        />
                        }
                        exact
                        path='/'
                    />
                    <Route
                        component={() => <Roles />}
                        exact
                        path='/roles'
                    />
                    <Route
                        component={() => 
                        <About
                            content={siteContent.about}
                        />
                        }
                        exact
                        path='/about'
                    />
                    <Route
                        component={() => 
                        <Apply
                            content={siteContent.apply}
                        />
                        }
                        exact
                        path='/apply'
                    />
                    <Route
                        component={() => 
                        <Pledge
                            content={siteContent.pledge}
                        />
                        }
                        exact
                        path='/pledge'
                    />
                    <Route
                        component={() => 
                        <Faqs
                            content={siteContent.faq}
                        />
                        }
                        exact
                        path='/faqs'
                    />
                    <Route
                        component={() => 
                        <Terms
                            content={siteContent.termsAndConditions}
                        />
                        }
                        exact
                        path='/terms'
                    />
                    <Route
                        component={FourZeroFour}
                    />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default withRouter(AppRouter);