import React from 'react';
import ConcatClasses from '../../utils/ConcatClasses';

import Image from '../simple/Image';
import LinkComp from '../simple/Link';
import Text from '../simple/Text';

import './_role.scss';

const Role = ({
    align = 'left',
    bg = 'light',
    className = '',
    children = '',
    copyBg='white',
    copy = '',
    heading = '',
    link = {
        href: '',
        text: '',
    },
    imgSrc = '',
    shape = '',
    tall = false,
}) => {
    const {
        href,
        text,
    } = link;

    const textColor = copyBg === 'blue' ||  copyBg === 'black' ? 'white' : '';
    // console.log(copyBg, textColor) ;

    const renderImage = () => {
        return (
            <div
                className={
                    ConcatClasses([
                        'image--wrapper',
                        tall ? 'tall--image' : '',
                ])}
            >
                <Image
                    className={
                        ConcatClasses([
                            'vis--hidden',
                            'vis--lrg-visible',
                    ])}
                    alt={heading}
                    src={imgSrc}
                />
            </div>
        )
    }

    return (
    <div
      className={
        ConcatClasses([
          className,
          'role--wrappper',
          'mar--lrg-l-100',
        ])
      }
    >
        <Text
            className={ConcatClasses([
                'heading--1',
                'heading--lrg-0',
                'mar--b-50',
                'mar--t-50',
                'text--black',
                copyBg === 'white' ? 'text--white' : '',
                'text--center',
                'z--1',
            ])}
        >
            {heading}
        </Text> 
        <div
            className={ConcatClasses([
                // 'flex',
                // 'flex--2-column',
                // shape,
                'mxh--lrg-700',
                'mar--0-auto',
                'pad--t-20',
                'width--70-p',
            ])}
        >

            {align === 'left' ? renderImage() : ''}
            <div
                className={ConcatClasses([
                    'mar--lrg-t-50',
                    shape,
                    'text--wrapper',
                ])}
            >
                <div
                    className={ConcatClasses([
                        'bg--' + copyBg,
                        'pad--20',
                        'pad--b-30',
                        'pad--t-30',
                        'z--1',
                    ])}
                >
                    <Text
                        className={ConcatClasses([
                            'text--' + textColor,
                        ])}
                    >
                        {copy}
                    </Text>
                    <div
                        className={
                            ConcatClasses([
                                'mar--t-30',
                                'vis--hidden',
                                'vis--lrg-visible',
                        ])}
                    >
                        <LinkComp
                            className={
                                ConcatClasses([
                                    'link--border',
                                    'text--black',
                                    'text--uppercase',
                                    bg === 'light' ? 'link--white' : 'link--black',
                                    bg === 'white' ? 'link--black' : '',
                            ])}
                            href={href}
                        >            
                            {text}
                        </LinkComp>
                    </div>
                </div>
            </div>

            {align === 'right' ? renderImage() : ''}
            {/* MOBILE LINK */}
            <div
                className={
                    ConcatClasses([
                        'text--center',
                        'width--100-p',
                    ])
                }
            >
                <LinkComp
                    className={
                        ConcatClasses([
                            'dis--block',
                            'link',
                            'link--border',
                            'link--black',
                            'mar--b-50',
                            'mar--t-50',
                            'text--center',
                            'text--black',
                            'text--uppercase',
                            'pos--rel',
                            'vis--lrg-hidden',
                            copyBg === 'white' ? 'link--white' : '',
                            'z--1',
                    ])}
                    href={href}
                >            
                    {text}
                </LinkComp>
            </div>
         </div>
    </div>
  );
}
export default Role;